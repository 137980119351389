import './App.scss';
import React from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Login from './Components/Common/Login/login';
import Notfound from './Components/Not Found/notFound';
import Signup from './Components/Common/Signup/signup';
import RouteItems from './Routes/routes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLogo } from './Redux/ActionCreators/Common/authAction';
import PrivateRoute from './Routes/privateRoutes';
import FacultySignup from './Components/Common/Signup/facultySignup';
const Sidebar = React.lazy(() => import('./Components/Layout/sidebar'));


function App() {
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();
  const { logoDetails } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLogo());
    document.title = logoDetails?.type === 'school' ? 'NTTF School' : 'Nttf Schools'
  }, [])


  useEffect(() => {
    setIsLogged(window.location.pathname !== '/login')
  }, [window.location.pathname === '/login'])

  useEffect(() => {
    detectBrowser();

    if (localStorage.getItem('loginToken')) {
      (localStorage.getItem('role') !== null && (localStorage.getItem('role') === 'student')) ?
        window.location.pathname === '/' && (
          navigate('/my-learning')
        ) :
        (localStorage.getItem('role') !== null && (localStorage.getItem('role').includes('state-admin'))) ?
          window.location.pathname === '/' && (
            navigate('/reports')
          ) :
          window.location.pathname === '/' && (
            navigate('/manage-content')
          )
    } else {
      window.location.pathname === '/' && (
        navigate('/login')
      )
    }

    document.title = window.location.pathname === '/login' ? 'NTTF Schools | Login' : localStorage.getItem('role') === 'student' ? 'NTTF Schools | Student' : localStorage.getItem('role') === 'faculty' ? 'NTTF Schools | Faculty' : localStorage.getItem('role') === 'sme' ? 'NTTF Schools | Subject Controller' : localStorage.getItem('role') === 'program manager' ? 'NTTF Schools | Program Manager' :
      'NTTF Schools | Admin'
  }, [])



  const detectBrowser = () => {
    let browser = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1: return "Edge";
        case agent.indexOf("edg/") > -1: return "Chromium based Edge (dev or canary)"; // Match also / to avoid matching for the older Edge
        case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
        case agent.indexOf("trident") > -1: return "IE";
        case agent.indexOf("firefox") > -1: return "Mozila Firefox";
        case agent.indexOf("safari") > -1: return "Safari";
        default: return "other";
      }
    })(window.navigator.userAgent.toLowerCase());
    localStorage.setItem('browser', browser)
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      localStorage.setItem('device', 'Mobile')
    }
    else {
      localStorage.setItem('device', 'desktop')
    }
  }


  if (process.env.NODE_ENV === 'production') {
    console.log = function no_console() { };
  }

  return (
    <div className="">

      {
        // (localStorage.getItem('loginToken') === null && window.location.pathname !== '/signup') ?
        //   <Navigate to='/login' /> :
        <Routes>
          {<Route path='/login' element={<Login />} />}
          <Route path='/signup' element={<Signup />} />
          <Route path='/faculty-signup' element={<FacultySignup />} />
          <Route element={<PrivateRoute />} >
            <Route element={<Sidebar />} >
              {(RouteItems || []).map((item) => {
                if (item.layout === "mainLayout") {
                  return (
                    <Route
                      key={item.path}
                      path={item.path}
                      element={item.component}
                    />
                  );
                }

              })
              }
            </Route>
          </Route>
          <Route path='*' element={<Notfound />} />
        </Routes>
      }


    </div>
  );
}

export default App;
