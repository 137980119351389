import React, { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Select, { components } from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';
import HistoryPopup from './historyPopup';
import ViewResource from '../../../utils/viewResource';
import {
    refreshTests, getQuizList, nextQuizList, getPracticeTest, getNextPracticeTest, refreshQuestion, getQuestions, resetHistoryLoading, getTestHistory, getAllSubjects,// test section
} from '../../../Redux/ActionCreators/Admin/reportAction';
import { getQuestionForEdit } from '../../../Redux/ActionCreators/Admin/manageContentAction';
import { TopicLoader } from '../../../Loader/loader';
import { useDispatch, useSelector } from 'react-redux';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
}

function TestModule(props) {

    const [quesView, setQuesView] = useState(false);
    const [activeStatus, setActiveStatus] = useState('test');
    const [testpage, setTestpage] = useState(1);
    const [quizpage, setQuizpage] = useState(1);
    const [subjectId, setSubjectId] = useState('');
    const [filterSubject, setFilterSubject] = useState(null);
    const [isView, setIsView] = useState(false);
    const [isHistory, setIsHistory] = useState(false);
    const [resource, setResource] = useState({});

    const dispatch = useDispatch();
    const { testsLoading, quizList, practiceTestList, quesLoading, quesList, ishLoading, testHistory, allSubjects } = useSelector(state => state.report);
    // const { questionData } = useSelector(state => state.myContent);

    useEffect(() => {
        dispatch(getAllSubjects());
    }, [])


    useEffect(() => {
        const getData = async () => {
            if (activeStatus === 'test') {
                await dispatch(getPracticeTest(testpage, subjectId));
                // await fetchTestData();
            } else {
                await dispatch(getQuizList(quizpage, subjectId));
                // await fetchQuizData();
            }
        }
        getData();
    }, [activeStatus, subjectId])

    const onChangeStatus = async (e) => {
        setActiveStatus(e);
        setTestpage(1);
        setQuizpage(1);
        setSubjectId('');
        setFilterSubject(null)
        await dispatch(refreshTests());

    }


    const fetchQuizData = () => {
        setQuizpage(quizpage + 1);
        dispatch(nextQuizList(quizpage + 1, subjectId));
    }

    const fetchTestData = () => {
        setTestpage(testpage + 1);
        dispatch(getNextPracticeTest(testpage + 1, subjectId));
    }


    const getFilterSubject = async (e) => {
        setFilterSubject(e);
        setSubjectId(e ? e.value : '');
        setQuizpage(1);
        setTestpage(1);
    }


    const getTestsHistory = async (id, question) => {
        console.log(id, question)
        setIsHistory(true);
        await dispatch(resetHistoryLoading());
        await dispatch(getTestHistory(id, question));
    }

    const openQuesView = async (id) => {
        setQuesView(true);
        await dispatch(refreshQuestion());
        await dispatch(getQuestions(id));
    }


    // useEffect(() => {
    //     setResource({ ...questionData, type1: 'Question' });
    // }, [questionData])

    const openView = async (el) => {
        const res = await dispatch(getQuestionForEdit(el));
        if (res?.type === 'GET_QUESTION_DETAILS') {
            setIsView(true);
            setResource({ ...res?.payload, type1: 'Question' });
        }
    }

    const closeDetails = () => {
        setIsView(false);
        setResource({});
    }



    return (
        <div>
            {!quesView ?
                <div className='test-wrapper'>
                    <div className="content-tabs d-flex justify-content-between" style={{ minHeight: 34, flexWrap: window.innerWidth > 767 ? '' : 'wrap', overflow: 'unset' }}>
                        <div>
                            {/* <button
                                className={
                                    activeStatus === "quiz" ? "active-tab" : null
                                }
                                onClick={() => onChangeStatus("quiz")}
                            >
                                Quizzes
                            </button>
                            &nbsp;&nbsp;&nbsp; */}
                            {/* <button
                                className={
                                    activeStatus === "test" ? "active-tab" : null
                                }
                                onClick={() => onChangeStatus("test")}
                            >
                                Practice tests
                            </button> */}
                            &nbsp;
                        </div>

                        <div style={{ width: window.innerWidth > 767 ? '25%' : '342px' }}>
                            <Select
                                components={{ DropdownIndicator }}
                                onChange={getFilterSubject}
                                placeholder='Subjects'
                                value={filterSubject}
                                options={allSubjects}
                                isClearable
                            />
                        </div>
                    </div>
                    <div className='completion-details mt-5'>
                        <div className="test-wise-details mt-3">
                            {activeStatus === 'quiz' ?
                                <ul>
                                    <InfiniteScroll
                                        dataLength={quizList !== undefined && quizList?.length} //This is important field to render the next data
                                        next={fetchQuizData}
                                        hasMore={true}
                                    >
                                        {testsLoading ?
                                            <TopicLoader /> :
                                            quizList !== undefined && !quizList.length ?
                                                <div className="empty-test">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-5 d-flex flex-column justify-content-center align-items-center no-test">
                                                                <h4>Oops! No tests available</h4>
                                                                <p>
                                                                    There are no quizzes available relevant to this Subject.
                                                                </p>
                                                            </div>
                                                            {window.innerWidth > 767 ? (
                                                                <div className="col-md-7 d-flex justify-content-center align-items-center">
                                                                    <img
                                                                        src="/images/empty.png"
                                                                        width="740px"
                                                                        height="350px"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        backgroundImage: "url(/images/empty.png)",
                                                                        backgroundPosition: "center",
                                                                        backgroundRepeat: "no-repeat",
                                                                        padding: 100,
                                                                        backgroundSize: "contain",
                                                                        width: 400,
                                                                    }}
                                                                ></div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div> :
                                                quizList !== undefined &&
                                                quizList.map((el, index) => {
                                                    return window.innerWidth > 767 ? (
                                                        <Fragment>
                                                            <li key={index} style={{ marginTop: 15 }}>
                                                                <div className="topic-name">
                                                                    {index + 1}. {el.title}
                                                                </div>
                                                                <div className="topic-time" >
                                                                    <p style={{ fontSize: 14, color: '#222222' }}>
                                                                        {el.subject_name} <span>Subject</span>
                                                                    </p>
                                                                </div>
                                                                <div className="topic-time" style={{ width: '5%' }}>
                                                                    <p>
                                                                        {el.no_ques} <span>Questions</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-score">
                                                                    <p>
                                                                        {el.no_students !== undefined && el.no_students} <span>Total taken</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-score">
                                                                    <p>
                                                                        {el.avg_score !== undefined && ((el.avg_score === 0 || el.avg_score == null) ? el.avg_score : el.avg_score?.toFixed(2))}% <span>Avg Score</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-history" style={{ width: '5%' }}>
                                                                    {
                                                                        el.no_students >= 1 &&
                                                                        <div className="d-flex" onClick={() => getTestsHistory(el._id, el.no_ques)}>
                                                                            <img src="/images/history.svg" alt="" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div onClick={() => { openQuesView(el._id) }} style={{ color: '#f5821f', fontWeight: 'bold', cursor: 'pointer' }}>
                                                                    View Details
                                                                </div>
                                                            </li>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <li>

                                                                <div className="last-taken1 ml-4" >
                                                                    <h3>
                                                                        {index + 1}. {el.title}
                                                                    </h3>
                                                                    {
                                                                        el.no_students >= 1 ? (
                                                                            <p>
                                                                                Total taken : {el.no_students} &nbsp;
                                                                                <img src="/images/info1.svg" onClick={() => getTestsHistory(el._id, el.no_ques)} style={{ verticalAlign: "top" }} alt="" />
                                                                            </p>)
                                                                            :
                                                                            <p className='d-flex flex-row'>
                                                                                {el.no_ques} Questions &nbsp;
                                                                            </p>
                                                                    }
                                                                </div>

                                                            </li>
                                                        </Fragment>
                                                    );
                                                })}
                                    </InfiniteScroll>
                                </ul>
                                :
                                <ul>
                                    <InfiniteScroll
                                        dataLength={practiceTestList !== undefined && practiceTestList?.length} //This is important field to render the next data
                                        next={fetchTestData}
                                        hasMore={true}
                                    >
                                        {testsLoading ?
                                            <TopicLoader /> :
                                            practiceTestList !== undefined && !practiceTestList.length ?
                                                <div className="empty-test">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-5 d-flex flex-column justify-content-center align-items-center no-test">
                                                                <h4>Oops! No tests available</h4>
                                                                <p>
                                                                    There are no tests available relevant to this Subject.
                                                                </p>
                                                            </div>
                                                            {window.innerWidth > 767 ? (
                                                                <div className="col-md-7 d-flex justify-content-center align-items-center">
                                                                    <img
                                                                        src="/images/empty.png"
                                                                        width="740px"
                                                                        height="350px"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        backgroundImage: "url(/images/empty.png)",
                                                                        backgroundPosition: "center",
                                                                        backgroundRepeat: "no-repeat",
                                                                        padding: 100,
                                                                        backgroundSize: "contain",
                                                                        width: 400,
                                                                    }}
                                                                ></div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div> :
                                                practiceTestList !== undefined &&
                                                practiceTestList.map((el, index) => {
                                                    return window.innerWidth > 767 ? (
                                                        <Fragment>
                                                            <li key={index} style={{ marginTop: 15 }}>
                                                                <div className="topic-name">
                                                                    {index + 1}. {el.title}
                                                                </div>
                                                                <div className="topic-time" >
                                                                    <p style={{ fontSize: 14, color: '#222222' }}>
                                                                        {el.subject_name} <span>Subject</span>
                                                                    </p>
                                                                </div>
                                                                <div className="topic-time" style={{ width: '5%' }}>
                                                                    <p>
                                                                        {el.no_ques} <span>Questions</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-score">
                                                                    <p>
                                                                        {el.no_students !== undefined && el.no_students} <span>Total taken</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-score">
                                                                    <p>
                                                                        {el.avg_score !== undefined && ((el.avg_score === 0 || el.avg_score == null) ? el.avg_score : el.avg_score?.toFixed(2))}% <span>Avg Score</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-history" style={{ width: '5%' }}>
                                                                    {
                                                                        el.no_students >= 1 &&
                                                                        <div className="d-flex" onClick={() => getTestsHistory(el._id, el.no_ques)}>
                                                                            <img src="/images/history.svg" alt="" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div onClick={() => { openQuesView(el._id) }} style={{ color: '#f5821f', fontWeight: 'bold', cursor: 'pointer' }}>
                                                                    View Details
                                                                </div>
                                                            </li>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <li>

                                                                <div className="last-taken1 ml-4" >
                                                                    <h3>
                                                                        {index + 1}. {el.title}
                                                                    </h3>
                                                                    {
                                                                        el.no_students >= 1 ? (
                                                                            <p>
                                                                                Total taken : {el.no_students} &nbsp;
                                                                                <img src="/images/info1.svg" onClick={() => getTestsHistory(el._id, el.no_ques)} style={{ verticalAlign: "top" }} alt="" />
                                                                            </p>)
                                                                            :
                                                                            <p className='d-flex flex-row'>
                                                                                {el.no_ques} Questions &nbsp;
                                                                            </p>
                                                                    }
                                                                </div>

                                                            </li>
                                                        </Fragment>
                                                    );
                                                })}
                                    </InfiniteScroll>
                                </ul>
                            }
                        </div>
                    </div>
                </div> :
                <div className='center-reports'>
                    <div className='reports-header  '>
                        <div className='d-flex justify-content-between align-items-center header-width'>
                            <h3><img src='/images/back.svg' alt='' style={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={() => setQuesView(false)} /> &nbsp;Question Details </h3>
                        </div>

                    </div>
                    <div className='center-table '>
                        <Table>
                            <thead>
                                <th>Question title</th>
                                <th>Attempted</th>
                                <th>Correct answered</th>
                                <th>View</th>
                            </thead>
                            <tbody>
                                {quesLoading ?
                                    <TopicLoader /> :
                                    quesList && quesList.map(el => {
                                        return (
                                            <tr>
                                                <td style={{ width: '50%', color: '#696873' }} dangerouslySetInnerHTML={{ __html: el.question_title }}></td>
                                                <td className='text-center'>{el.no_attempts}</td>
                                                <td className='text-center'>{el.correct_answered}</td>
                                                <td className='text-center'><img src='/images/view_grey.svg' alt='' className='cursor-pointer' width={25} onClick={() => openView(el)} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            }


            {/* //historypopup */}
            {
                isHistory ?
                    <HistoryPopup
                        testHistory={testHistory}
                        closePopup={() => setIsHistory(false)}
                        ishistoryLoading={ishLoading}
                        type='test-history'
                    />
                    : null
            }

            {/* // view question */}
            <div className="resource-details" style={{ display: isView ? 'block' : 'none', }}>
                <ViewResource
                    activeFeedData={resource}
                    closeNotes={closeDetails}
                />
            </div>
        </div >
    )
}




export default TestModule;
