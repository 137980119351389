// report.js
import React, { Fragment, useEffect, useState } from 'react';
import './reports.scss';
import Highcharts, { chart } from 'highcharts';
import Modal from 'react-bootstrap/Modal';
import HighchartsReact from 'highcharts-react-official';
import TableComponent from './tableComponent';
import {
    openIndividualItem, backToMain, refreshData,
    getStudentLoginTrends, getStudentScoreTrends, getListOfStudent, getStudentReportDetail, getStudentReportDetail1, resetStudentDetails,
    resetStudentLoading, getScoresHistory, getNextScoresHistory, changeSubject, getTopicsForStudent, onResetSubject, getSubtopics, resetSubtopicsData, getTestList, getQuizHistory, clearQuizHistory, getClasses,//student
    getCourseGraph, getTopCourses, getCoursesList, resetProgramDetails, sortClassroomList,  //Courses
    getSubjectList, getNextSubjectList, resetSubjectDetails, getTopChapterCompletion, getAllChapterCompletion, searchChapterResult, refreshChapterData, sortSubjectList, resetSubjectLoading,  //subject
    getFacultyList, sortFacultyList, resetFacultyLoading, getFacultySubject, resetFacultyDetails, sortFacultySubjectList, resetFacultySubjectChapter, getFacultyContentCount, isHideChapter, getFacultyLoginTrends, getFacultyScoreTrends,//faculty
} from '../../../Redux/ActionCreators/Admin/reportAction';
import Select, { components } from 'react-select';
import IndividualSection from './individualSection';
import { BarChart, TopicLoader } from '../../../Loader/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { reverseArr } from '../../../utils/miscellneous';
import TestModule from './testModule';
import { getClassroomList } from '../../../Redux/ActionCreators/Admin/manageContentAction';
import { useDispatch, useSelector } from 'react-redux';


const filters = [
    {
        value: 'name', label: 'Student Name'
    },
    {
        value: 'total_engagement', label: 'Engagement Score'
    }
]
const Facultyfilters = [
    {
        value: 'name', label: 'Faculty Name'
    },
    {
        value: 'total_engagement', label: 'Engagement Score'
    }
]
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
}

function AdminReports() {
    const check = (localStorage.getItem('role') === 'master-admin' || localStorage.getItem('role') === 'super-admin')
    const [isReady, setIsReady] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [page, setPage] = useState(1);
    const [facultyPage, setFacultyPage] = useState(1);
    const [subjectPage, setSubjectPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState('');
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [activeTab, setActiveTab] = useState(check ? 'school' : 'course');
    const [query, setQuery] = useState('');
    const [subjectQuery, setSubjectQuery] = useState('');
    const [facultyQuery, setFacultyQuery] = useState('');
    const [sort, setSort] = useState({ label: 'Engagement Score', value: 'total_engagement' });
    const [facultySort, setFacultySort] = useState({ label: 'Engagement Score', value: 'total_engagement' });
    const [loadCenter, setLoadCenter] = useState(false);
    const [courseId, setCourseId] = useState('');
    const [course, setCourse] = useState('');
    const [current, setCurrent] = useState('');
    const [current1, setCurrent1] = useState('');
    const [studentId, setStudentId] = useState('');
    const [cls, setClass] = useState('');
    const [clsId, setClassId] = useState('');
    const courseHead = [{ title: 'course', label: 'Classroom Name', sort: false }, { title: 'students', label: '#Students', sort: true }, { title: 'engagement_score', label: 'Total Engagement Score', sort: true }, { title: 'avg_score', label: 'Avg Engagement Score', sort: true }, { title: 'monthly_active_users', label: 'Active Users (Last 30 days)', sort: true }];
    const subjectHead = [{ title: 'title', label: 'Subject Name', sort: false }, { title: 'course_name', label: 'Course', sort: false }, { title: 'students', label: '#Students', sort: true }, { title: 'less_than_35', label: '<35% Complete', sort: true }, { title: 'range_35_to_49', label: '35-49% Complete', sort: true }, { title: 'range_50_to_89', label: '50-89% Complete', sort: true }, { title: 'range_90_to_100', label: '90-100% Complete', sort: true }];
    const studentHead = [{ title: 'name', label: 'Student Name', sort: false }, { title: 'course_name', label: 'Course', sort: false }, { title: 'engagement_score', label: 'Engagement Score (Overall)', sort: false }, { title: 'monthly_engagement_score', label: 'Engagement Score (This month)', sort: false }, { title: 'monthly_no_of_logins', label: 'No of logins(This month)', sort: false }, { title: 'lastLogin', label: 'Last Login Date', sort: false }];
    const chapterHead = [{ title: 'title', label: 'Chapter Name' }, { title: 'students', label: '#Students' }, { title: 'less_than_35', label: '<35% Complete' }, { title: 'range_35_to_49', label: '35-49% Complete' }, { title: 'range_50_to_89', label: '50-89% Complete' }, { title: 'range_90_to_100', label: '90-100% Complete' }];
    const chapterSectionHead = [{ title: 'title', label: 'Section Name' }, { title: 'students', label: '#Students' }, { title: 'less_than_35', label: '<35% Complete' }, { title: 'range_35_to_49', label: '35-49% Complete' }, { title: 'range_50_to_89', label: '50-89% Complete' }, { title: 'range_90_to_100', label: '90-100% Complete' }];

    const subjectHead1 = [{ title: 'name', label: 'Subject Name' }, { title: 'program', label: 'Program' }, { title: 'semester', label: 'Semester' }, { title: 'students', label: '#Students' }, { title: 'less35', label: '<35% Complete' }, { title: 'more35', label: '35-49% Complete' }, { title: 'more50', label: '50-89% Complete' }, { title: 'more90', label: '90-100% Complete' }];
    // { title: 'monthly_engagement_score', label: 'Engagement Score (Last 30 days)', sort: true } { title: 'content', label: 'Content added' },
    const facultyHead = [{ title: 'name', label: 'Faculty Name', sort: false }, { title: 'subject', label: 'Subject', sort: false }, { title: 'engagement_score', label: 'Engagement Score (Overall)', sort: false }, { title: 'monthly_engagement_score', label: 'Engagement Score (This month)', sort: false }, { title: 'lastLogin', label: 'Last Login Date', sort: false }];
    const facultySubjectHead = [{ title: 'title', label: 'Subject Name', sort: false }, { title: 'students', label: '#Students', sort: true }, { title: 'less_than_35', label: '<35% Complete', sort: true }, { title: 'range_35_to_49', label: '35-49% Complete', sort: true }, { title: 'range_50_to_89', label: '50-89% Complete', sort: true }, { title: 'range_90_to_100', label: '90-100% Complete', sort: true }];
    const timerRef1 = React.useRef(null);
    const timerRef2 = React.useRef(null);
    const timerRef3 = React.useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const firstRender = async () => {
            window.scrollTo(0, 0);
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            };
            if (window.location.pathname === '/reports') {
                setActiveTab('course');
                setLoadCenter(false);
                setLoadCenter(true);
                dispatch(backToMain());
            }
            await getCalls();

        }
        firstRender();
    }, [])


    const getCalls = () => {
        // //course
        dispatch(getTopCourses());
        dispatch(getCourseGraph());
        dispatch(getCoursesList());

        //student
        dispatch(getStudentLoginTrends('student'));
        dispatch(getStudentScoreTrends('student'));
        dispatch(getClassroomList());
        dispatch(getClasses());
    }


    const onTabChange = async (e) => {
        setActiveTab(e);
        setOpen(false);
        setQuery('');
        setSort({ label: 'Engagement Score', value: 'total_engagement' });
        setFacultySort({ label: 'Engagement Score', value: 'total_engagement' });
        setCurrent('');
        setCurrent1('');
        setCourse(null);
        setCourseId('');
        setClassId('');
        setSubjectPage(1)
        setPage(1);
        dispatch(refreshData());
        if (e === 'school') {
            setCourseId('');
            setPage(1);
            dispatch(getListOfStudent(page, query, courseId, clsId, sort?.value, 'student'));
        }
        else if (e === 'student') {
            setCourseId('');
            setPage(1);
            dispatch(getListOfStudent(page, query, courseId, clsId, sort?.value, 'student'));
        }
        else if (e === 'subject') {
            setSubjectQuery('');
            dispatch(getSubjectList(1, ''));
        }
        else if (e === 'adminfaculty') {
            setFacultyPage(1);
            setFacultyQuery('');
            dispatch(getFacultyLoginTrends('staff'));
            dispatch(getFacultyScoreTrends('staff'));
            dispatch(getFacultyList(1, '', facultySort?.value));
        }
    };

    const checkFocus = () => {
        // dummy element
        var dummyEl = document.getElementById("express-form-typeahead");
        // check for focus
        var isFocused = document.activeElement === dummyEl;
        if (isFocused) {
            setIsFocused(isFocused);
        } else {
            setIsFocused(false);
        }
    };

    //student
    const handleChange = (value) => {
        clearTimeout(timerRef1.current);
        setQuery(value);
        setPage(1);
        timerRef1.current = setTimeout(() => getStudentQuery(value), 800);
    }

    const getStudentQuery = (val) => {
        dispatch(resetStudentLoading());
        dispatch(getListOfStudent(page, val, courseId, clsId, sort?.value, 'student'));
    }

    const openFilter = () => {
        setIsOpenFilter(true);
        setCourse('');
        setCourseId('');
        setClassId('');
        setClass('');
    }

    const selectCourse = (e) => {
        setCourse(e);
        setCourseId(e.value);
    }

    const selectClass = async (e) => {
        setClass(e);
        setClassId(e.value);
    }

    const applyFilter = async () => {
        setIsOpenFilter(false);
        setQuery('');
        setPage(1);
        await dispatch(resetStudentLoading());
        await dispatch(getListOfStudent(1, '', courseId, clsId, sort?.value, 'student'));
    }


    const getSortStudent = async (e) => {
        setSort(e);
        setPage(1);
        await dispatch(resetStudentLoading());
        await dispatch(getListOfStudent(1, query, courseId, clsId, e.value, 'student'));
    }

    const getStudentId = (id) => {
        setStudentId(id);
    }


    const nextPage = () => {
        if (activeTab === 'student') {
            setPage(page + 1)
            dispatch(getListOfStudent(page + 1, query, courseId, clsId, sort?.value, 'student'));
        }
        else if (activeTab === 'adminfaculty') {
            setFacultyPage(facultyPage + 1);
            dispatch(getFacultyList(facultyPage + 1, facultyQuery, facultySort?.value));
        }
    }

    const prevPage = () => {
        if (activeTab === 'student') {
            setPage(page - 1);
            dispatch(getListOfStudent(page - 1, query, courseId, clsId, sort?.value, 'student'));
        }
        else if (activeTab === 'adminfaculty') {
            setFacultyPage(facultyPage - 1);
            dispatch(getFacultyList(facultyPage - 1, facultyQuery, facultySort?.value));
        }
    }

    const currentPage = async () => {
        if (activeTab === 'student') {
            setPage(Number(current))
            await dispatch(resetStudentLoading());
            await dispatch(getListOfStudent(Number(current), query, courseId, clsId, sort.value, 'student'));
        }
        else if (activeTab === 'adminfaculty') {
            setFacultyPage(Number(current1));
            await dispatch(resetFacultyLoading());
            await dispatch(getFacultyList(Number(current1), facultyQuery, facultySort.value));
        }
    }


    //faculty

    const handleChange1 = async (value) => {
        await dispatch(resetFacultyLoading());
        clearTimeout(timerRef2.current);
        setFacultyQuery(value);
        setFacultyPage(1)
        timerRef2.current = setTimeout(() => getFacultyQuery(value), 800);
    }
    const getFacultyQuery = (val) => {
        dispatch(getFacultyList(facultyPage, val, facultySort.value));
    }

    const getSortFaculty = async (e) => {
        setFacultySort(e);
        setFacultyPage(1);
        await dispatch(resetFacultyLoading());
        await dispatch(getFacultyList(1, facultyQuery, e.value));
    }

    //subject
    const fetchData = () => {
        setSubjectPage(subjectPage + 1);
        dispatch(getNextSubjectList(subjectPage + 1, subjectQuery));
    }

    const searchSubject = async (val) => {
        await dispatch(resetSubjectLoading());
        clearTimeout(timerRef3.current);
        setSubjectQuery(val);
        setSubjectPage(1);
        timerRef3.current = setTimeout(() => getquerySearch(val), 800);
    }

    const getquerySearch = (val) => {
        dispatch(getSubjectList(subjectPage, val));
    }


    const { openIndividual, centerObj, type,
        schoolStudentList, schoolData, schoolLoginDates, dates, schoolLoginTrends, schoolScoreTrends, num_of_logins,
        isStudentLoading, studentList, totalPages, scoreDetails, studentTren, scoreHistory, sems, semsforTest, total_page, dataLoading, dataLoading1, dataLoading2,
        subjects1, subjectContent, subjectComplete, contents, subject, testComplete, subjectCount, testCount, testLoading, activeSub, activeSubId, subjectTopics,
        courseData, topCourseGraph, courseStudentGraph, courseName, legands, ishistoryLoading, isSubtopicLoading, testList, quizHistory, engagement_score, isShowChapter, classes,
        months1, score, scores, months,
        subjectData, subjectList, subjectAverage, subjectStudent, chapterLoading, chapterList, chapterData, sectionChapterData, searchSectionChapterResult, chaptersData,
        chapterAverage, chapterStudent, sectionChapterList, sectionChapterAverage, sectionChapterStudent,
        isloading, isFacultyLoading, facultyList, facultySubjectData, facultyTotalPage, contentCount, countLoading, faculty_num_of_logins, facultyTren, facultydates, faculty_score,
    } = useSelector(state => state.report);
    const { classroomList } = useSelector(state => state.manageContent);

    // School graph
    const schoolGraph = {
        chart: {
            type: 'column',
            height: 270,
        },
        legend: {
            enabled: false
        },

        title: {
            text: ''
        },

        colors: ['#00B24B', '#ECC30B'],
        xAxis: {
            categories: schoolData !== undefined && schoolData?.map(el => el.name),
            type: 'Schools',
            // events: {
            //     afterSetExtremes: afterSetExtremes
            // }
        },
        yAxis: {
            type: 'Counts',
            min: 0,
            visible: false,
            title: {
                text: ''
            },
        },
        plotOptions: {
            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'hidden',
                    formatter: function () {
                        // eslint-disable-next-line eqeqeq
                        if (this.y != 0) {
                            return this.y;
                        }
                    }

                },
                pointWidth: window.innerWidth > 767 ? 25 : 12

            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Engagement-score',
            data: schoolData !== undefined && schoolData?.map(el => el.engagement_score),
            enableMouseTracking: false,
        },
        {
            name: 'Student Count',
            data: schoolData !== undefined && schoolData?.map(el => el.students),
            enableMouseTracking: false,
        }]
    }

    //courses graph
    const courseGraph = {
        chart: {
            type: 'pie',
            height: 300,
        },
        title: {
            text: 'Classroom Student Distribution'
        },

        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                // valueSuffix: '%'
            }
        },
        credits: {
            enabled: false
        },

        colors: courseStudentGraph !== undefined && courseStudentGraph.map(el => el.color),
        plotOptions: {
            pie: {
                dataLabels: {
                    // connectorColor: 'transparent',
                    connectorPadding: 5,
                    distance: 20,
                    enabled: true,

                },
            },
            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    format: `{point.name} <br/>  {point.y}`,
                }
            },

        },

        tooltip: {
            enabled: false,

        },

        series: [
            {
                name: "Courses",
                colorByPoint: true,
                enableMouseTracking: false,
                data: courseStudentGraph !== undefined && courseStudentGraph
            }
        ],

    }

    const courseEngagement = {
        chart: {
            type: 'bar',
            height: 300,

        },
        title: {
            text: openIndividual ? 'Top  Engagement Classes' : 'Top Avg Engagement Classrooms'
        },
        colors: ['#00B24B', '#FF5A5F', '#ECC30B', '#6457E8', '#3ABEBC', '#1D8BCB'],
        xAxis: {
            categories: courseName !== undefined && courseName,
            title: {
                text: null
            }
        },
        yAxis: {
            visible: true,
            title: false,
            labels: {
                enabled: false
            },
            gridLineColor: 'transparent',
            stackLabels: {
                enabled: true,
                formatter: function () {
                    var val = this.total;
                    if (val > 0) {
                        return val;
                    }
                    return '';
                },
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || '#166B9C'
                }
            },
        },

        plotOptions: {
            series: {
                animation: false,
                pointPadding: 0.1,
                groupPadding: 0.1,
            },
            bar: {
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'hidden',
                    formatter: function () {
                        var pcnt = (this.y);
                        if (pcnt > 0) {
                            return pcnt;
                        }
                        else {
                            return '';
                        }

                    }
                },

            }
        },

        credits: {
            enabled: false
        },
        series: [
            {
                enableMouseTracking: false,
                showInLegend: false,
                data: topCourseGraph !== undefined && topCourseGraph
            }
        ]

    }

    //subject graph
    const subjectSectionGraph = {
        chart: {
            type: 'column',
            height: 270,
        },
        legend: {
            enabled: false
        },
        title: {
            text: ''
        },

        colors: ['#00B24B', '#ECC30B'],
        xAxis: {
            categories: sectionChapterList !== undefined && sectionChapterList,
            type: 'Subject',
        },
        yAxis: {
            type: 'Counts',
            min: 0,
            visible: false,
            title: {
                text: ''
            },

        },
        plotOptions: {
            series: {
                dataLabels: {
                    animation: false,
                    enabled: true,
                    crop: false,
                    overflow: false,
                    formatter: function () {
                        // eslint-disable-next-line eqeqeq
                        if (this.y != 0) {
                            return this.y;
                        }
                    }

                },
                pointWidth: window.innerWidth > 767 ? 25 : 10
            }
        },
        credits: {
            enabled: false
        },
        series:
            [{
                name: 'Average Completion',
                data: sectionChapterAverage !== undefined && sectionChapterAverage,
                enableMouseTracking: false,
            }, {
                name: 'Student Count',
                data: sectionChapterStudent !== undefined && sectionChapterStudent,
                enableMouseTracking: false,
            },
            ]

    }

    const subjectGraph = {
        chart: {
            type: 'column',
            height: 270,
            // scrollablePlotArea: {
            //     minWidth: 1500,
            //     opacity: 1
            //   },
        },
        legend: {
            enabled: false
        },
        title: {
            text: ''
        },

        colors: ['#00B24B', '#ECC30B'],
        xAxis: {
            categories: openIndividual ? chapterList !== undefined && chapterList : subjectList !== undefined && subjectList,
            type: 'Subject',
        },
        yAxis: {
            type: 'Counts',
            min: 0,
            visible: false,
            title: {
                text: ''
            },

        },
        plotOptions: {
            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: false,
                    formatter: function () {
                        // eslint-disable-next-line eqeqeq
                        if (this.y != 0) {
                            return this.y;
                        }
                    }

                },
                pointWidth: window.innerWidth > 767 ? 25 : 10
            }
        },
        credits: {
            enabled: false
        },
        series: openIndividual ? [{
            name: 'Average Completion',
            data: chapterAverage !== undefined && chapterAverage,
            enableMouseTracking: false,
        }, {
            name: 'Student Count',
            data: chapterStudent !== undefined && chapterStudent,
            enableMouseTracking: false,
        },
        ] :
            [{
                name: 'Average Completion',
                data: subjectAverage !== undefined && subjectAverage,
                enableMouseTracking: false,
            }, {
                name: 'Student Count',
                data: subjectStudent !== undefined && subjectStudent,
                enableMouseTracking: false,
            },
            ]

    }

    //faculty tab graph
    const facultyTrend = {
        chart: {
            type: 'line',
            height: 300
        },
        title: {
            text: openIndividual ? '' : 'Last 10 days login trend '
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: facultydates !== undefined && facultydates,
            visible: true
        },
        colors: ['#ECC30B'],
        yAxis: {
            visible: false,
            min: 0,
            // max:100,
            tickInterval: 10,
            title: {
                //   text: 'Temperature (°C)'
            }

        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series: [{
            name: 'Tokyo',
            data: faculty_num_of_logins !== undefined && faculty_num_of_logins
        },
        ]
    }
    const facultyEngagementTrend = {
        chart: {
            type: 'line',
            height: 300
        },
        title: {
            text: 'Last 10 days engagement score trend'
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: facultydates !== undefined && facultydates,
            visible: true
        },
        colors: ['#FF5A5F'],
        yAxis: {
            visible: false,
            min: 0,
            // max:100,
            tickInterval: 10
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series: [{
            name: 'Tokyo',
            data: faculty_score !== undefined && faculty_score
        },
        ]
    }
    const engagementScoreGraph = {
        chart: {
            type: 'column',
            height: 270,
        },
        legend: {
            enabled: false
        },

        title: {
            text: ''
        },

        colors: ['#E56E25'],
        xAxis: {
            categories: months1 !== undefined && months1,
            type: 'Months',
        },
        yAxis: {
            type: 'Scores',
            min: 0,
            // max: emptyMainArray[0] - 10,
            tickInterval: 30,
            visible: false,
            title: {
                text: ''
            },

        },
        plotOptions: {
            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: false,
                    formatter: function () {
                        // eslint-disable-next-line eqeqeq
                        if (this.y != 0) {
                            return this.y;
                        }
                    }

                }
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {
                enableMouseTracking: false,
                showInLegend: false,
                grouping: false,
                color: '#008080',
                data: scores !== undefined && scores,
            }
        ]
    }
    //student tab graph
    const studentTrend = {
        chart: {
            type: 'line',
            height: 300
        },
        title: {
            text: openIndividual ? 'Last 10 days login trend' : 'Last 10 days login trend'
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: openIndividual ? schoolLoginDates : dates !== undefined && dates,
            visible: true
        },
        colors: ['#ECC30B'],
        yAxis: {
            visible: false,
            min: 0,
            // max:100,
            tickInterval: 10,
            title: {
                //   text: 'Temperature (°C)'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series:
            [{
                name: 'Tokyo',
                data: openIndividual ? (schoolLoginTrends !== undefined && schoolLoginTrends) : num_of_logins !== undefined && num_of_logins
            },
            ]
    }
    const engagementTrend = {
        chart: {
            type: 'line',
            height: 300
        },
        title: {
            text: 'Last 10 days engagement score trend'
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: openIndividual ? (schoolLoginDates !== undefined && schoolLoginDates) : dates !== undefined && dates,
            visible: true
        },
        colors: ['#FF5A5F'],
        yAxis: {
            visible: false,
            min: 0,
            // max:100,
            tickInterval: 10
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series: [{
            name: 'Tokyo',
            // used in section                                                       ##############used in student
            data: openIndividual ? (schoolScoreTrends !== undefined && schoolScoreTrends) : score !== undefined && score
        },
        ]
    }
    const subjectWiseGraph = {
        chart: {
            type: 'bar',
            height: 170
        },
        title: {
            text: ''
        },
        colors: ['#FF5A5F', '#ECC30B', '#00B24B', '#5EB5E7'],
        xAxis: {
            categories: subjects1 !== undefined && subjects1,
            labels: {
                align: 'left',
                reserveSpace: true,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Source Sans pro',
                    color: '#696873',
                    align: 'left'
                }
            }
        },
        yAxis: {
            visible: true,
            title: false,
            labels: {
                enabled: false
            },
            gridLineColor: 'transparent',
            stackLabels: {
                enabled: true,
                formatter: function () {
                    var val = this.total;
                    if (val > 0) {
                        return val;
                    }
                    return '';
                },
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || '#166B9C'
                }
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            bar: {
                pointPadding: 0,
                borderWidth: 0,
                groupPadding: 0.01
            },
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },

            series: {
                animation: false,
                enableMouseTracking: false,
                stacking: 'normal',
                pointWidth: 15,
                dataLabels: {
                    enabled: false,
                    crop: false,
                    overflow: false
                },
                hover: {
                    enabled: false
                }

            },

        },
        series: reverseArr(subjectContent !== undefined && subjectContent)
    }
    const monthlyWise = {
        chart: {
            type: 'column',
            height: 190
        },
        legend: {
            enabled: false
        },
        tooltips: {
            enabled: false
        },
        title: {
            text: ''
        },
        colors: ['#5EB5E7', '#00B24B', '#ECC30B', '#FF5A5F'],
        xAxis: {
            categories: months !== undefined && months,
            type: 'Months'
        },
        yAxis: {
            type: 'Scores',
            min: 0,
            max: 100,
            tickInterval: 20,
            labels: {
                format: '{value}%'
            },
            title: {
                text: ''
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                animation: false,
                enableMouseTracking: false,
                hover: {
                    enabled: false
                }

            }
        },
        tooltip: {
            enabled: false
        },
        series: contents !== undefined && contents
    }


    if (activeTab === 'faculty') {
        var percent = facultyTren !== undefined && facultyTren.filter(el => el.login !== false)
        var percentage = Math.round((percent.length / (facultyTren !== undefined && facultyTren.length)) * 100);
    }
    return (
        <div className='reports-holder'>
            <Modal
                show={isReady}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"ready-modal"}
            >
                <div className='p-4 text-center'>
                    <h1>The data shown here is for representational purpose only. Actual data and reports will be available after 30 days.</h1>
                    <button onClick={() => setIsReady(false)}>Ok</button>
                </div>
            </Modal>
            {
                !openIndividual ?
                    <Fragment>
                        <h3>Reports</h3>
                        <div className="content-tabs mt-2">

                            <Fragment>
                                <button
                                    className={
                                        activeTab === "course" ? "active-tab" : null
                                    }
                                    onClick={() => onTabChange("course")}
                                >
                                    Classrooms
                                </button>
                                &nbsp;
                                <button
                                    className={
                                        activeTab === "subject" ? "active-tab" : null
                                    }
                                    onClick={() => onTabChange("subject")}
                                >
                                    Subjects
                                </button>
                                &nbsp;
                                <button
                                    className={
                                        activeTab === "student" ? "active-tab" : null
                                    }
                                    onClick={() => onTabChange("student")}
                                >
                                    Students
                                </button>
                                &nbsp;

                                <button
                                    className={
                                        activeTab === "adminfaculty" ? "active-tab" : null
                                    }
                                    onClick={() => onTabChange("adminfaculty")}
                                >
                                    Faculty
                                </button>
                                &nbsp;
                                <button
                                    className={
                                        activeTab === "test" ? "active-tab" : null
                                    }
                                    onClick={() => onTabChange("test")}
                                >
                                    Tests
                                </button>&nbsp;
                            </Fragment>


                        </div>
                        {

                            activeTab === 'course' ?
                                <div className='center-reports mt-2'>
                                    <div className='program-graph '>
                                        {
                                            courseStudentGraph ?
                                                <Fragment>
                                                    <div className='row ml-0 mr-0'>
                                                        <div className='col-md-6'>
                                                            <HighchartsReact
                                                                highcharts={Highcharts}
                                                                options={courseGraph}
                                                                oneToOne={true}
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <HighchartsReact
                                                                highcharts={Highcharts}
                                                                options={courseEngagement}
                                                                oneToOne={true}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className='row ml-0 mr-0'>
                                                        <div className='program-legand mt-3' >
                                                            {
                                                                legands !== undefined && legands.map((el, i) => {
                                                                    return (
                                                                        window.innerWidth > 767 ?
                                                                            <Fragment>
                                                                                <div style={{ background: el.color }} className='legand'></div> &nbsp; &nbsp;<p>{el.name}</p>
                                                                            </Fragment>
                                                                            :
                                                                            <div className='col-3 d-flex'>
                                                                                <div style={{ background: el.color }} className='legand'></div> &nbsp; &nbsp;<p>{el.name}</p>
                                                                            </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </Fragment> :
                                                <div className='text-center mt-5'>
                                                    <BarChart />
                                                </div>
                                        }
                                    </div>
                                    <div className='reports-header  '>
                                        <div className='d-flex justify-content-between align-items-center header-width'>
                                            <h3>Classrooms Engagement Detailed Data </h3>
                                        </div>
                                    </div>
                                    <div className='center-table '>
                                        <TableComponent
                                            header={courseHead}
                                            centerList={courseData}
                                            type='classroom'
                                            sortClassroomList={(sortkey, asc) => dispatch(sortClassroomList(sortkey, asc))}
                                            openIndividualItem={(type, center) => dispatch(openIndividualItem(type, center))}
                                            resetProgramDetails={() => dispatch(resetProgramDetails())}
                                            setShow={(val) => setShow(val)}
                                        />
                                    </div>
                                </div> :

                                activeTab === 'subject' ?
                                    <div className='center-reports mt-2'>
                                        <div className='reports-header  '>
                                            <div className='d-flex justify-content-between align-items-center header-width'>
                                                <h3>Subject Engagament Detailed Data </h3>
                                                {
                                                    window.innerWidth > 767 ? null :
                                                        <div className='ml-4' onClick={() => {
                                                            setOpen(!open);
                                                            setSubjectQuery(!open ? '' : subjectQuery);
                                                        }} >
                                                            <img src='/images/search.svg' alt='' />
                                                        </div>
                                                }
                                            </div>
                                            {
                                                window.innerWidth > 767 ?
                                                    <div class="animated-search" style={{ top: 18 }}>
                                                        <input
                                                            id="express-form-typeahead"
                                                            autoComplete="off"
                                                            onBlur={checkFocus}
                                                            onFocus={checkFocus}
                                                            spellCheck="false"
                                                            type="text"
                                                            value={subjectQuery}
                                                            onChange={(e) => searchSubject(e.target.value)}
                                                        />
                                                        <div class="symbol">
                                                            <img src="/images/search.svg" alt="" />
                                                        </div>
                                                        {isFocused ? (
                                                            <img
                                                                src="/images/close2.png"
                                                                alt=""
                                                                className="close-input"
                                                                onClick={() => setIsFocused(false)}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div> : null

                                            }
                                            {
                                                open ?
                                                    <input name='search' type='search' autoFocus className='form-control mt-1' autoComplete='off' value={subjectQuery} onChange={(e) => searchSubject(e.target.value)} placeholder='Search' />
                                                    : null
                                            }
                                        </div>
                                        <div className='center-table '>
                                            <InfiniteScroll
                                                dataLength={subjectData && subjectData.length} //This is important field to render the next data
                                                next={fetchData}
                                                hasMore={true}
                                                loader={<div >{isloading ?
                                                    <div style={{ background: '#F6F6F6' }}>
                                                        <TopicLoader />
                                                    </div> : null}</div>}
                                            >{
                                                    subjectData &&
                                                    <TableComponent
                                                        header={subjectHead}
                                                        centerList={subjectData}
                                                        sortSubjectList={(sortkey, asc) => dispatch(sortSubjectList(sortkey, asc))}
                                                        resetSubjectDetails={() => dispatch(resetSubjectDetails())}
                                                        getTopChapterCompletion={(sid, cid) => dispatch(getTopChapterCompletion(sid, cid))}
                                                        getAllChapterCompletion={(sid, cid) => dispatch(getAllChapterCompletion(sid, cid))}
                                                        type='subject'
                                                        setShow={(val) => setShow(val)}
                                                        openIndividualItem={(type, center) => dispatch(openIndividualItem(type, center))}
                                                    />
                                                }
                                            </InfiniteScroll>
                                        </div>
                                    </div> :
                                    activeTab === "adminfaculty" ?
                                        <div className='center-reports mt-2'>
                                            <div className='program-graph '>
                                                {
                                                    faculty_num_of_logins && faculty_score ?
                                                        <div className='row ml-0 mr-0'>
                                                            <div className='col-md-6'>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={facultyTrend}
                                                                />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={facultyEngagementTrend}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='text-center mt-4'>
                                                            <BarChart />
                                                        </div>
                                                }
                                            </div>
                                            <div className='reports-header  '>
                                                <div className='d-flex justify-content-between align-items-center header-width'>
                                                    <h3>Faculty Engagement Detailed Data  </h3>
                                                    <div className='d-flex align-items-center cursor-pointer' style={{ width: window.innerWidth > 767 ? '36%' : '70%', }} >
                                                        <p style={{ width: '33%', }}>Sort by</p>
                                                        <div style={{ width: '100%', zIndex: window.innerWidth > 767 ? 1 : 6 }} >
                                                            <Select
                                                                components={{ DropdownIndicator }}
                                                                options={Facultyfilters}
                                                                onChange={getSortFaculty}
                                                                placeholder='Select'
                                                                value={facultySort}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        window.innerWidth > 767 ? null :
                                                            <div className='ml-4' onClick={() => {
                                                                setOpen(!open)
                                                                setFacultyQuery(!open ? '' : facultyQuery)
                                                            }
                                                            }>
                                                                <img src='/images/search.svg' alt='' />
                                                            </div>
                                                    }
                                                </div>
                                                {
                                                    window.innerWidth > 767 ?
                                                        <div class="animated-search" style={{ top: 24 }}>
                                                            <input
                                                                id="express-form-typeahead"
                                                                autoComplete="off"
                                                                onBlur={checkFocus}
                                                                onFocus={checkFocus}
                                                                spellCheck="false"
                                                                type="text"
                                                                onChange={(e) => handleChange1(e.target.value)}
                                                            />
                                                            <div class="symbol">
                                                                <img src="/images/search.svg" alt="" />
                                                            </div>
                                                            {isFocused ? (
                                                                <img
                                                                    src="/images/close2.png"
                                                                    alt=""
                                                                    className="close-input"
                                                                    onClick={() => setIsFocused(false)}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div> : null

                                                }
                                                {
                                                    open ?
                                                        <input name='search' type='search' autoFocus className='form-control mt-2' autoComplete='off' value={facultyQuery} onChange={(e) => handleChange1(e.target.value)} placeholder='Search' />
                                                        : null
                                                }
                                            </div>
                                            <div className='center-table '>
                                                {
                                                    isFacultyLoading ?
                                                        <div style={{ background: '#F6F6F6' }}>
                                                            <TopicLoader />
                                                        </div>
                                                        :
                                                        <TableComponent
                                                            header={facultyHead}
                                                            centerList={facultyList}
                                                            type='adminfaculty'
                                                            openIndividualItem={(type, center) => dispatch(openIndividualItem(type, center))}
                                                            setShow={(val) => setShow(val)}
                                                            sortFacultyList={(sortkey, asc) => dispatch(sortFacultyList(sortkey, asc))}
                                                            getFacultySubject={(id) => dispatch(getFacultySubject(id))}
                                                            getFacultyContentCount={(id) => dispatch(getFacultyContentCount(id))}
                                                            resetFacultyDetails={() => dispatch(resetFacultyDetails())}
                                                        />
                                                }
                                            </div>
                                            {!isFacultyLoading &&
                                                facultyTotalPage > 1 && <div className='user-pagination'>
                                                    {
                                                        facultyPage === 1 ?
                                                            <img src='/images/page_prev_disable.svg' alt='' />
                                                            : <img src='/images/page_prev_active.svg' onClick={prevPage} alt='' />
                                                    }
                                                    &nbsp;&nbsp;
                                                    <div>Page&nbsp;&nbsp; <input value={facultyPage} readOnly disabled />&nbsp;of {facultyTotalPage}</div>&nbsp;
                                                    {
                                                        facultyPage === facultyTotalPage ?
                                                            <img src='/images/page_next_disable.svg' alt='' /> :
                                                            <img src='/images/page_next_active.svg' onClick={nextPage} alt='' />
                                                    }
                                                    Go to page &nbsp;<input className='iput' value={current1} onChange={(e) => setCurrent1(e.target.value)} />&nbsp; <button style={{ pointerEvents: current1 > facultyTotalPage ? 'none' : 'auto' }} onClick={current1 > facultyTotalPage ? '' : currentPage} className='go-btn'>Go</button>
                                                </div>
                                            }
                                        </div>
                                        :
                                        activeTab === "test" ?
                                            <TestModule
                                                type='report'
                                            />
                                            : activeTab === "student" ?
                                                <div className='center-reports mt-2'>
                                                    {
                                                        (localStorage.getItem('role').includes('master') || localStorage.getItem('role') === 'admin')
                                                        &&
                                                        <div className='program-graph '>
                                                            {
                                                                score && num_of_logins
                                                                    ?
                                                                    <div className='row ml-0 mr-0'>
                                                                        <div className='col-md-6'>
                                                                            <HighchartsReact
                                                                                highcharts={Highcharts}
                                                                                options={studentTrend}
                                                                            />
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <HighchartsReact
                                                                                highcharts={Highcharts}
                                                                                options={engagementTrend}
                                                                            />
                                                                        </div>

                                                                    </div> :
                                                                    <div className='text-center mt-4'>
                                                                        <BarChart />
                                                                    </div>
                                                            }

                                                        </div>
                                                    }
                                                    <div className='reports-header  '>
                                                        <div className='d-flex justify-content-between align-items-center header-width'>
                                                            <h3>Student Engagement Detailed Data  </h3>
                                                            <div className='d-flex align-items-center cursor-pointer' onClick={openFilter}>
                                                                <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p>
                                                            </div>
                                                            <div className='d-flex align-items-center cursor-pointer' style={{ width: window.innerWidth > 767 ? '35%' : '70%', }} >
                                                                <p style={{ width: '34%', }}>Sort by</p>
                                                                <div style={{ width: '100%', zIndex: window.innerWidth > 767 ? 1 : 6 }} >
                                                                    <Select
                                                                        components={{ DropdownIndicator }}
                                                                        options={filters}
                                                                        onChange={getSortStudent}
                                                                        placeholder='Select'
                                                                        value={sort}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                window.innerWidth > 767 ? null :
                                                                    <div className='ml-4' onClick={() => {
                                                                        setOpen(!open);
                                                                        setQuery(!open ? '' : query)
                                                                    }}>
                                                                        <img src='/images/search.svg' alt='' />
                                                                    </div>
                                                            }
                                                        </div>
                                                        {
                                                            window.innerWidth > 767 ?
                                                                <div class="animated-search" style={{ top: 24 }}>
                                                                    <input
                                                                        id="express-form-typeahead"
                                                                        autoComplete="off"
                                                                        onBlur={checkFocus}
                                                                        onFocus={checkFocus}
                                                                        spellCheck="false"
                                                                        type="text"
                                                                        value={query}
                                                                        onChange={(e) => handleChange(e.target.value)}
                                                                    />
                                                                    <div class="symbol">
                                                                        <img src="/images/search.svg" alt="" />
                                                                    </div>
                                                                    {isFocused ? (
                                                                        <img
                                                                            src="/images/close2.png"
                                                                            alt=""
                                                                            className="close-input"
                                                                            onClick={() => setIsFocused(false)}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div> : null

                                                        }
                                                        {
                                                            open ?
                                                                <input name='search' type='search' autoFocus className='form-control mt-2' autoComplete='off' value={query} onChange={(e) => handleChange(e.target.value)} placeholder='Search' />
                                                                : null
                                                        }
                                                    </div>
                                                    <div className='center-table '>
                                                        {
                                                            isStudentLoading ?
                                                                <div style={{ background: '#F6F6F6' }}>
                                                                    <TopicLoader />
                                                                </div>
                                                                :
                                                                <TableComponent
                                                                    header={studentHead}
                                                                    centerList={studentList}
                                                                    type='student'
                                                                    openIndividualItem={(type, center) => dispatch(openIndividualItem(type, center))}
                                                                    getStudentReportDetail={(id) => dispatch(getStudentReportDetail(id))}
                                                                    getStudentReportDetail1={(id) => dispatch(getStudentReportDetail1(id))}
                                                                    getStudentId={getStudentId}
                                                                    resetStudentDetails={() => dispatch(resetStudentDetails())}
                                                                />
                                                        }
                                                    </div>
                                                    {
                                                        !isStudentLoading &&
                                                        total_page > 1 &&
                                                        <div className='user-pagination'>
                                                            {
                                                                page === 1 ?
                                                                    <img src='/images/page_prev_disable.svg' alt='' />
                                                                    : <img src='/images/page_prev_active.svg' onClick={prevPage} alt='' />
                                                            }
                                                            &nbsp;&nbsp;
                                                            <div>Page&nbsp;&nbsp; <input value={page} readOnly disabled />&nbsp;of {total_page}</div>&nbsp;
                                                            {
                                                                page === total_page ?
                                                                    <img src='/images/page_next_disable.svg' alt='' /> :
                                                                    <img src='/images/page_next_active.svg' onClick={nextPage} alt='' />
                                                            }&nbsp;&nbsp;
                                                            Go to page &nbsp;<input value={current} className='iput' onChange={(e) => setCurrent(e.target.value)} />&nbsp; <button style={{ pointerEvents: current > total_page ? 'none' : 'auto' }} onClick={current > total_page ? '' : currentPage} className='go-btn'>Go</button>
                                                        </div>
                                                    }
                                                </div> :
                                                null
                        }
                    </Fragment> :
                    <IndividualSection
                        activeTab={activeTab}
                        centerObj={centerObj}
                        type={type}
                        backToMain={() => dispatch(backToMain())}

                        //school 
                        schoolStudentList={schoolStudentList}
                        totalPages={totalPages}
                        studentHead={studentHead}
                        // getSchoolStudents={() => dispatch(getSchoolStudents())}
                        schoolLoginDates={schoolLoginDates}
                        schoolLoginTrends={schoolLoginTrends}

                        //course
                        courseGraph={courseGraph}
                        courseEngagement={courseEngagement}
                        show={show}

                        //subject
                        subjectGraph={subjectGraph}
                        chapterData={chapterData}
                        chapterHead={chapterHead}
                        subjectSectionGraph={subjectSectionGraph}
                        chapterSectionHead={chapterSectionHead}
                        sectionChapterData={sectionChapterData}
                        searchChapterResult={(val) => dispatch(searchChapterResult(val))}
                        searchSectionChapterResult={(val) => dispatch(searchSectionChapterResult(val))}
                        refreshChapterData={() => dispatch(refreshChapterData())}
                        subjectHead={subjectHead1}
                        subjectList={subjectList}
                        chaptersData={chaptersData}
                        //students
                        dataLoading={dataLoading}
                        dataLoading1={dataLoading1}
                        dataLoading2={dataLoading2}
                        scoreDetails={scoreDetails}
                        subjectWiseGraph={subjectWiseGraph}
                        sems={sems}
                        studentTrend={studentTrend}
                        semsforTest={semsforTest}
                        monthlyWise={monthlyWise}
                        studentTren={studentTren}
                        scoreHistory={scoreHistory}
                        // fetchData={fetchData}
                        studentId={studentId}
                        ishistoryLoading={ishistoryLoading}
                        getScoresHistory={(id, page) => dispatch(getScoresHistory(id, page))}
                        getNextScoresHistory={(id, page) => dispatch(getNextScoresHistory(id, page))}
                        subject={subject}
                        subjectComplete={subjectComplete}
                        testComplete={testComplete}
                        subjectCount={subjectCount}
                        testCount={testCount}
                        testLoading={testLoading}
                        activeSub={activeSub}
                        activeSubId={activeSubId}
                        changeSubject={(title, id) => dispatch(changeSubject(title, id))}
                        getTopicsForStudent={(sid, id) => dispatch(getTopicsForStudent(sid, id))}
                        subjectTopics={subjectTopics}
                        onResetSubject={() => dispatch(onResetSubject())}
                        chapterLoading={chapterLoading}
                        getSubtopics={(idx, sid, uid) => dispatch(getSubtopics(idx, sid, uid))}
                        isSubtopicLoading={isSubtopicLoading}
                        resetSubtopicsData={() => dispatch(resetSubtopicsData())}
                        getTestList={(sid, uid) => dispatch(getTestList(sid, uid))}
                        testList={testList}
                        getQuizHistory={(tid, sid, uid) => dispatch(getQuizHistory(tid, sid, uid))}
                        quizHistory={quizHistory}
                        clearQuizHistory={() => dispatch(clearQuizHistory())}
                        //faculty
                        engagementScoreGraph={engagementScoreGraph}
                        scores={scores}
                        engagement_score={engagement_score}
                        facultyTren={facultyTren}
                        facultySubjectHead={facultySubjectHead}
                        facultySubjectData={facultySubjectData}
                        openIndividualItem={(type, center) => dispatch(openIndividualItem(type, center))}
                        sortFacultySubjectList={(sortkey, asc) => dispatch(sortFacultySubjectList(sortkey, asc))}
                        isShowChapter={isShowChapter}
                        isHideChapter={isHideChapter}
                        getTopChapterCompletion={(id, cid) => dispatch(getTopChapterCompletion(id, cid))}
                        getAllChapterCompletion={(id, cid) => dispatch(getAllChapterCompletion(id, cid))}
                        setShow={(val) => setShow(val)}
                        resetFacultySubjectChapter={() => dispatch(resetFacultySubjectChapter())}
                        contentCount={contentCount}
                        countLoading={countLoading}
                    />
            }
            <Modal
                show={isOpenFilter}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"update-modal1"}
            >
                <Modal.Body style={{ padding: '22px 40px 22px 40px' }}>
                    <div>
                        <label>Course</label>
                        <div className='mt-2'>
                            <Select
                                components={{ DropdownIndicator }}
                                options={classroomList?.map(el => [{ ...el, label: el.name, value: el._id }][0])}
                                onChange={selectCourse}
                                placeholder='Select Course'
                                value={course}
                            />
                        </div>

                    </div>
                    <div>
                        <label>Class</label>
                        <div className='mt-2'>
                            <Select
                                components={{ DropdownIndicator }}
                                options={classes}
                                onChange={selectClass}
                                placeholder='Select Class'
                                value={cls}
                            />
                        </div>
                    </div>
                    <div className='save-btn'>
                        <button onClick={() => applyFilter()}>Apply</button>
                        <button onClick={() => setIsOpenFilter(false)}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}




export default AdminReports;
