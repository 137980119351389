import {
    GET_SCHOOLS, GET_STATE_DATA, GET_SCORE_TREND, GET_LOGIN_TREND, GET_STUDENT_DATA,
    OPEN_INDIVIDUAL, CLOSE_iNDIVIDUAL, REFRESH_DATA,
    GET_ALL_SCHOOL, SCHOOL_LOGIN_TRENDS, SCHOOL_SCORE_TRENDS, GET_SCHOOL_STUDENTS,                     //School
    GET_TOP_COURSES, GET_COURSE_GRAPH, GET_COURSE_DATA, RESET_COURSE_DETAILS, SORT_CLASSROOM,//Courses
    GET_SUBJECTS_LIST, GET_NEXT_SUBJECTS_LIST, RESET_SUB, GET_SUBJECT_CHAPTER_DATA, GET_CHAPTER_DATA, SEARCH_CHAPTER, REFRESH_CHPATER_DATA, SORT_SUBJECT, RESET_SUBJECT_LOADING, //Subjects
    RESET_FACULTY_DETAILS, FACULTY_LOGIN_TRENDS, FACULTY_SCORE_TRENDS, GET_FACULTY_DATA, GET_FACULTY_SUBJECT_DATA, GET_FACULTY_CONTENT_COUNT, SORT_FACULTY, SORT_FACULTY_SUBJECT, RESET_FACULTY_LOADING, RESET_FACULTY_SUBJECT_LOADING, HIDE_CHAPTER, //faculty
    STUDENT_LOGIN_TRENDS, STUDENT_SCORE_TRENDS, RESET_STUDENT_LOADING, STUDENT_LIST, STUDENT_REPORT, STUDENT_REPORT1, GET_SUBJECT_COMPLETION, GET_ACTIVE_CONTENTS, RESET_STUDENT_DETAILS, CHANGE_SUBJECTS, GET_ACTIVE_SUBJECT_TOPICS, GET_TEST_LIST1, RESET_SUBTOPICS_DATA, //students
    GET_ACTIVE_SUBJECT_SUBTOPICS, GET_SUBTOPIC_QUIZ_HISTORY, CLEAR_QUIZ_HISTORY, GET_CLASSES,
    GET_SCORE_HISTORY, GET_NEXT_SCORE_HISTORY, RESET_HISTORY, REFRESH_TEST, REFRESH_QUESTION, REFRESH_HISTORY, GET_QUIZ_LIST, GET_NEXT_QUIZ_LIST, GET_TEST_LISTS, GET_NEXT_TEST_LISTS, GET_QUESTIONS_LIST, GET_TEST_HISTORY,
    GET_ALL_SUBJECTS, //tests
} from "../../Types/types";


let intialState = {
    schools: [],
    openIndividual: false,
    openIndividual1: false,
    dataLoading: true,
    dataLoading1: true,
    dataLoading2: false,
    subjectData: [],
    isloading: true,
    sectionData: [],
    studentList: [],
    isStudentLoading: true,
    isFacultyLoading: true,
    isShowChapter: false,
    chapterData: [],
    sectionChapterData: [],
    countLoading: true,
    ishistoryLoading: true,
    isAttendanceLoading: true,
    isSubtopicLoading: true,
    ishLoading: true,
    testsLoading: true,
    quesLoading: true
}

function getSortData(asc, key, arr) {
    if (asc) {
        return arr.sort((a, b) => (a[key] < b[key] ? 1 : -1));
    }
    else {
        return arr.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    }
}

export default function reportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_SCHOOLS:
            stateCopy.stateSchools = action.payload;
            return stateCopy;
        case GET_STATE_DATA:
            stateCopy.stateData = action.payload.map(el => [{ name: el.name, total_students: el.total_students, total_time: el.total_time, average_time: el.average_time, active_students: el.active_students, _id: el._id }][0]);
            return stateCopy;
        case GET_SCORE_TREND:
            stateCopy.scoreTrend = action.payload;
            return stateCopy;
        case GET_LOGIN_TREND:
            stateCopy.loginTrend = action.payload;
            return stateCopy;
        case GET_STUDENT_DATA:
            stateCopy.studentData = action.payload;
            return stateCopy;

        //admin reports
        case OPEN_INDIVIDUAL:
            stateCopy.openIndividual = true;
            stateCopy.centerObj = action.payload.el;
            stateCopy.type = action.payload.type;
            if (action.payload.type === 'adminsubject') {
                stateCopy.isShowChapter = true;
            }
            return stateCopy;
        case 'Hide_Chapter':
            stateCopy.isShowChapter = false;
            return stateCopy;
        case CLOSE_iNDIVIDUAL:
            stateCopy.openIndividual = false;
            stateCopy.centerObj = {};
            stateCopy.centerData = stateCopy.centerDataCopy;
            stateCopy.sectionData = stateCopy.sectionDataCopy;
            stateCopy.isShowChapter = false;
            return stateCopy;
        case REFRESH_DATA:
            stateCopy.centerData = stateCopy.centerDataCopy;
            stateCopy.sectionData = stateCopy.sectionDataCopy;
            return stateCopy;

        //School
        case GET_ALL_SCHOOL:
            stateCopy.schoolData = action.payload.map(el => [{ name: el.name, students: el.students, engagement_score: el.engagement_score, monthly_active_users: el.monthly_active_users, avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            return stateCopy;
        case SCHOOL_LOGIN_TRENDS:
            stateCopy.schoolLoginDates = action.payload.dates;
            stateCopy.schoolLoginTrends = action.payload.num_of_logins;
            return stateCopy;
        case SCHOOL_SCORE_TRENDS:
            stateCopy.schoolLoginDates = action.payload.dates;
            stateCopy.schoolScoreTrends = action.payload.num_of_engagementscore;
            return stateCopy;
        case GET_SCHOOL_STUDENTS:
            stateCopy.schoolStudentList = action.payload.result.map(el => [{ name: el.name, course_name: el.course, section: el.section, engagement_score: el.engagement_score, monthly_engagement_score: el.monthly_engagement_score, monthly_no_of_logins: el.monthly_no_of_logins, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);
            stateCopy.totalPages = action.payload.total_page;
            stateCopy.isStudentLoading = false;
            return stateCopy
        //Courses
        case GET_COURSE_GRAPH:
            var colors = ['#00B24B', '#FF5A5F', '#ECC30B', '#6457E8', '#3ABEBC', '#1D8BCB', '#EF5DA8']
            stateCopy.courseStudentGraph = action.payload.data.map((el, i) => [{ name: el.name, y: el.students_count === 0 ? null : el.students_count, color: colors.map(a => a)[i] }][0]);
            return stateCopy;
        case GET_TOP_COURSES:
            stateCopy.topCourseGraph = action.payload.result.map(el => [{ color: el.color, y: el.engagement_score }][0]);
            stateCopy.courseName = action.payload.result.map(el => [el.name][0])
            stateCopy.legands = action.payload.result.map(el => [{ name: el.name, color: el.color }][0])
            return stateCopy;
        case GET_COURSE_DATA:
            stateCopy.courseData = action.payload.data.map(el => [{ course: el.course, students: el.students, engagement_score: el.engagement_score, avg_score: el.avg_score ? el.avg_score : 0, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            return stateCopy;
        case RESET_COURSE_DETAILS:
            stateCopy.topSemesterGraph = [];
            stateCopy.semNames = [];
            stateCopy.semesterStudentGraph = [];
            return stateCopy;
        case SORT_CLASSROOM:
            const data2 = getSortData(action.payload.asc, action.payload.key, stateCopy.courseData);
            stateCopy.courseData = data2;
            return stateCopy;
        //subjects
        case GET_SUBJECTS_LIST:
            stateCopy.subjectData = action.payload.data.map(el => [{ title: el.subject_name, course_name: el.course_name, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100, subject_id: el.subject_id, course_id: el.course_id }][0]);
            stateCopy.isloading = false;
            return stateCopy;
        case GET_NEXT_SUBJECTS_LIST:
            stateCopy.subjectData = stateCopy.subjectData.concat(action.payload.data.map(el => [{ title: el.subject_name, course_name: el.course_name, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100, subject_id: el.subject_id, course_id: el.course_id }][0]));
            stateCopy.isloading = false;
            return stateCopy;
        case RESET_SUB:
            stateCopy.chapterList = [];
            stateCopy.chapterAverage = [];
            stateCopy.chapterStudent = [];
            stateCopy.chapterData = [];
            return stateCopy;
        case GET_SUBJECT_CHAPTER_DATA:
            stateCopy.chapterData = action.payload.data.map(el => [{ title: el.chapter_name, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100 }][0]);
            stateCopy.chapterDataCopy = stateCopy.chapterData;
            return stateCopy;
        case SEARCH_CHAPTER:
            if (action.payload === '') {
                stateCopy.chapterData = stateCopy.chapterDataCopy;
            }
            else {
                const filterResult = stateCopy.chapterDataCopy.filter(el => { return el.chapter_name.toLowerCase().includes(action.payload.toLowerCase()) })
                stateCopy.chapterData = filterResult;
            }
            return stateCopy;
        case GET_CHAPTER_DATA:
            stateCopy.chaptersData = action.payload;
            return stateCopy;
        case REFRESH_CHPATER_DATA:
            stateCopy.chapterData = stateCopy.chapterDataCopy;
            stateCopy.sectionChapterData = stateCopy.sectionchapterDataCopy;
            return stateCopy;
        case SORT_SUBJECT:
            const data7 = getSortData(action.payload.asc, action.payload.key, stateCopy.subjectData)
            stateCopy.subjectData = data7;
            return stateCopy;
        case RESET_SUBJECT_LOADING:
            stateCopy.subjectData = [];
            stateCopy.isloading = true;
            return stateCopy;


        //faculty
        case RESET_FACULTY_DETAILS:
            stateCopy.facultyTren = [];
            stateCopy.facultySubjectData = [];
            stateCopy.contentCount = {};
            stateCopy.countLoading = true;
            stateCopy.scoreHistory = [];
            stateCopy.ishistoryLoading = true;
            stateCopy.isAttendanceLoading = true;
            return stateCopy;
        case FACULTY_LOGIN_TRENDS:
            stateCopy.facultydates = action.payload.dates;
            stateCopy.faculty_num_of_logins = action.payload.num_of_logins;
            return stateCopy;
        case FACULTY_SCORE_TRENDS:
            stateCopy.facultydates = action.payload.dates;
            stateCopy.faculty_score = action.payload.num_of_engagementscore;
            return stateCopy;
        case GET_FACULTY_DATA:
            // monthly_engagement_score: el.monthly_engagement_score,content: (el.article_added + el.video_added + el.document_added + el.question_added),
            stateCopy.facultyList = action.payload.result.map(el => [{ name: el.name, subject: el.subject, engagement_score: el.score, monthly_engagement_score: el.monthly_engagement_score, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);;
            stateCopy.facultyTotalPage = action.payload.total_page;
            stateCopy.isFacultyLoading = false;
            return stateCopy;
        case RESET_FACULTY_LOADING:
            stateCopy.isFacultyLoading = true;
            return stateCopy;
        case SORT_FACULTY:
            const data8 = getSortData(action.payload.asc, action.payload.key, stateCopy.facultyList)
            stateCopy.facultyList = data8;
            return stateCopy;
        case GET_FACULTY_SUBJECT_DATA:
            stateCopy.facultySubjectData = action.payload.data.map(el => [{ title: el.subject_name, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100, program_id: el.course_id, _id: el._id }][0]);
            return stateCopy;
        case SORT_FACULTY_SUBJECT:
            const data9 = getSortData(action.payload.asc, action.payload.key, stateCopy.facultySubjectData)
            stateCopy.facultySubjectData = data9;
            return stateCopy;
        case GET_FACULTY_CONTENT_COUNT:
            stateCopy.contentCount = action.payload;
            stateCopy.countLoading = false;
            return stateCopy;
        case RESET_FACULTY_SUBJECT_LOADING:
            stateCopy.chapterData = [];
            stateCopy.sectionChapterData = [];
            return stateCopy;


        //Students
        case STUDENT_LOGIN_TRENDS:
            stateCopy.dates = action.payload.dates;
            stateCopy.num_of_logins = action.payload.num_of_logins;
            return stateCopy;
        case STUDENT_SCORE_TRENDS:
            stateCopy.dates = action.payload.dates;
            stateCopy.score = action.payload.num_of_engagementscore;
            return stateCopy;
        case RESET_STUDENT_LOADING:
            stateCopy.isStudentLoading = true;
            return stateCopy;
        case STUDENT_LIST:
            stateCopy.studentList = action.payload.result.map(el => [{ name: el.name, course_name: el.course, engagement_score: el.engagement_score, monthly_engagement_score: el.monthly_engagement_score, monthly_no_of_logins: el.monthly_no_of_logins, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);
            stateCopy.total_page = action.payload.total_page;
            stateCopy.isStudentLoading = false;
            return stateCopy;
        case STUDENT_REPORT:
            stateCopy.scoreDetails = action.payload.data;
            stateCopy.subjects1 = action.payload.subjects;
            stateCopy.subjectContent = action.payload.subjectContent;
            var len = action.payload.data.total_semesters;
            var arr = []
            for (var i = 0; i < len; i++) {
                if (action.payload.data.current_semester === i + 1) {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: true,
                        user_id: action.payload.id
                    });
                }
                else {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: false,
                        user_id: action.payload.id
                    });
                }
            }
            stateCopy.sems = arr;
            stateCopy.semsforTest = arr;
            stateCopy.dataLoading = false;
            return stateCopy;
        case STUDENT_REPORT1:
            stateCopy.months = action.payload.month;
            stateCopy.contents = action.payload.contents;
            const arr1 = [];
            action.payload.logins.dates.forEach(function (v, i) {
                var obj = {};
                obj.date = v;
                obj.login = action.payload.logins.logins[i];
                arr1.push(obj);
            });
            stateCopy.studentTren = arr1;
            stateCopy.dataLoading1 = false;
            return stateCopy;
        case GET_SUBJECT_COMPLETION:
            stateCopy.subject = action.payload.subject2.subject;
            stateCopy.subjectComplete = action.payload.subject2.complete;
            stateCopy.subjectCount = action.payload.subject2.count;
            stateCopy.testComplete = action.payload.test1.complete;
            stateCopy.testCount = action.payload.test1.count;
            stateCopy.activeSub = action.payload.subject2.subject.length ? action.payload.subject2.subject[0].name : '';
            stateCopy.activeSubId = action.payload.subject2.subject.length ? action.payload.subject2.subject[0]._id : '';
            stateCopy.testLoading = false;
            stateCopy.dataLoading2 = false;
            return stateCopy;

        case RESET_STUDENT_DETAILS:
            stateCopy.scoreDetails = '';
            stateCopy.months = [];
            stateCopy.contents = [];
            stateCopy.subjects = [];
            stateCopy.subjectContent = [];
            stateCopy.subject = [];
            stateCopy.complete = [];
            stateCopy.dataLoading = true;
            stateCopy.dataLoading1 = true;
            stateCopy.dataLoading2 = true;
            stateCopy.ishistoryLoading = true;
            stateCopy.isAttendanceLoading = true;
            stateCopy.scoreHistory = [];
            stateCopy.abilities = [];
            stateCopy.ability = [];
            stateCopy.scores = [];
            return stateCopy;
        case GET_ACTIVE_CONTENTS:
            stateCopy.subjectTopics = action.payload;
            // stateCopy.testList = action.payload.testList;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case CHANGE_SUBJECTS:
            stateCopy.activeSub = action.payload.sub;
            stateCopy.activeSubId = action.payload.subId;
            return stateCopy;
        case GET_ACTIVE_SUBJECT_TOPICS:
            stateCopy.subjectTopics = action.payload;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case GET_TEST_LIST1:
            stateCopy.testList = action.payload;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case GET_ACTIVE_SUBJECT_SUBTOPICS:
            console.log(action.payload)
            stateCopy.subjectTopics.forEach((el, i) => {
                if (i === action.payload.key) {
                    return el.data = action.payload.data
                }
            })
            stateCopy.isSubtopicLoading = false;
            return stateCopy;
        case RESET_SUBTOPICS_DATA:
            stateCopy.isSubtopicLoading = true;
            return stateCopy;
        case GET_SUBTOPIC_QUIZ_HISTORY:
            stateCopy.quizHistory = action.payload;
            return stateCopy;
        case CLEAR_QUIZ_HISTORY:
            stateCopy.quizHistory = [];
            return stateCopy;
        case GET_CLASSES:
            stateCopy.classes = action.payload.map(el => [{ ...el, label: `Class ${el.class}`, value: el.class }][0]);
            return stateCopy;
        case GET_SCORE_HISTORY:
            stateCopy.scoreHistory = action.payload;
            stateCopy.ishistoryLoading = false;
            return stateCopy;
        case GET_NEXT_SCORE_HISTORY:
            stateCopy.scoreHistory = stateCopy.scoreHistory.concat(action.payload);
            stateCopy.ishistoryLoading = false;
            return stateCopy;
        case RESET_HISTORY:
            stateCopy.scoreHistory = [];
            stateCopy.ishistoryLoading = true;
            return stateCopy;

        //tests
        case REFRESH_TEST:
            stateCopy.testsLoading = true;
            return stateCopy;
        case REFRESH_HISTORY:
            stateCopy.ishLoading = true;
            return stateCopy;
        case REFRESH_QUESTION:
            stateCopy.quesList = [];
            stateCopy.quesLoading = true;
            return stateCopy;
        case GET_QUIZ_LIST:
            stateCopy.quizList = action.payload;
            stateCopy.testsLoading = false;
            return stateCopy;
        case GET_NEXT_QUIZ_LIST:
            stateCopy.quizList = stateCopy.quizList.concat(action.payload);
            stateCopy.testsLoading = false;
            return stateCopy;
        case GET_TEST_LISTS:
            stateCopy.practiceTestList = action.payload;
            stateCopy.testsLoading = false;
            return stateCopy;
        case GET_NEXT_TEST_LISTS:
            stateCopy.practiceTestList = stateCopy.practiceTestList.concat(action.payload);
            stateCopy.testsLoading = false;
            return stateCopy;
        case GET_QUESTIONS_LIST:
            stateCopy.quesList = action.payload;
            stateCopy.quesLoading = false;
            return stateCopy;
        case GET_TEST_HISTORY:
            stateCopy.testHistory = { ...action.payload.data, question: action.payload.question };
            stateCopy.ishLoading = false;
            return stateCopy;
        case GET_ALL_SUBJECTS:
            stateCopy.allSubjects = action.payload.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;
        default:
            return stateCopy;
    }
}

