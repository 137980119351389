//Auth
export const GET_LOGO = 'GET_LOGO';
export const GET_STATES = 'GET_STATES';
export const GET_STATE_SCHOOLS = 'GET_STATE_SCHOOLS'
export const LOGIN_USER = 'LOGIN_USER';
export const AUTO_LOGIN = 'AUTO_LOGIN';

//sidebar
export const GET_MENUS = 'GET_MENUS';

//Error
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

//Account
export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';
export const GET_SCHOOL_LIST = 'GET_SCHOOL_LIST';
export const ADD_SCHOOL = 'ADD_SCHOOL';
export const EDIT_SCHOOL = 'EDIT_SCHOOL';
export const UPDATE_SCHOOL_STATUS = 'UPDATE_SCHOOL_STATUS';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const GET_STATE_LIST = 'GET_STATE_LIST';
export const ADD_STATE = 'ADD_STATE';
export const EDIT_STATE = 'EDIT_STATE';
export const UPDATE_STATE_STATUS = 'UPDATE_STATE_STATUS';
export const UPDATE_STATE = 'UPDATE_STATE';
export const DELETE_STATE_OR_SCHOOL = 'DELETE_STATE_OR_SCHOOL';
export const GET_FACULTY_LIST = 'GET_FACULTY_LIST';
export const ADD_USER = 'ADD_USER';

export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const IS_ADD_SCHOOL = 'IS_ADD_SCHOOL';
export const BACK_TO_ACCOUNT = 'BACK_TO_ACCOUNT';
export const RESET_LIST = 'RESET_LIST';
export const GET_COLORS = 'GET_COLORS';
export const APPLY_THEME = 'APPLY_THEME';

//manage-content
export const RESET_TABS = 'RESET_TABS';
export const GET_ACTIVE_BTN = 'GET_ACTIVE_BTN';
export const GET_AFFLIATIONS = 'GET_AFFLIATIONS';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const OPEN_ADD_CLASSROOM = 'OPEN_ADD_CLASSROOM';
export const BACK_TO_CLASSROOM = 'BACK_TO_CLASSROOM';
export const RESET_PROGRAM_LOADING = 'RESET_PROGRAM_LOADING';
export const GET_CLASSROOM_LIST = 'GET_CLASSROOM_LIST';
export const ADD_CLASSROOM = 'ADD_CLASSROOM';
export const UPDATE_CLASSROOM = 'UPDATE_CLASSROOM';
export const UPDATE_CLASSROOM_STATUS = 'UPDATE_CLASSROOM_STATUS';
export const DELETE_CLASSROOM = 'DELETE_CLASSROOM';
export const OPEN_ADD_TO_CLASSROOM = 'OPEN_ADD_TO_CLASSROOM';
export const CLOSE_ADD_TO_CLASSROOM = 'CLOSE_ADD_TO_CLASSROOM';
export const GET_SUBJECT_FROM_PROGRAM = 'GET_SUBJECT_FROM_PROGRAM';
export const GET_CHECKED = 'GET_CHECKED';
export const SELECT_ONE = 'SELECT_ONE';
export const ADD_SUB_TO_CLASSROOM = 'ADD_SUB_TO_CLASSROOM';
export const OPEN_ADD_SUBJECT = 'OPEN_ADD_SUBJECT';
export const CLOSE_ADD_SUBJECT = 'CLOSE_ADD_SUBJECT';
export const GET_SUBJECT_LIST = 'GET_SUBJECT_LIST';
export const GET_NEXT_SUBJECT_LIST = 'GET_NEXT_SUBJECT_LIST';
export const ADD_SUBJECT = 'ADD_SUBJECT';
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';
export const UPDATE_SUBJECT_STATUS = 'UPDATE_SUBJECT_STATUS';
export const DELETE_SUBJECT = 'DELETE_SUBJECT';
export const SUBJECT_CLONE = 'SUBJECT_CLONE';
export const GET_CHAPTERS_LIST = 'GET_CHAPTERS_LIST';
export const ADD_CHAPTER = 'ADD_CHAPTER';
export const UPDATE_CHAPTER = 'UPDATE_CHAPTER';
export const UPDATE_CHAPTER_STATUS = 'UPDATE_CHAPTER_STATUS';
export const DELETE_CHAPTER = 'DELETE_CHAPTER';
export const REORDER_CHAPTER = 'REORDER_CHAPTER';
export const OPEN_ADD_INFO = 'OPEN_ADD_INFO';
export const BACK_TO_CHAPTER = 'BACK_TO_CHAPTER';
export const ADD_SUBJECT_BRIEF = 'ADD_SUBJECT_BRIEF';
export const GET_TOPICS_LIST = 'GET_TOPICS_LIST';
export const ADD_TOPIC = 'ADD_TOPIC';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const UPDATE_TOPIC_STATUS = 'UPDATE_TOPIC_STATUS';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const REORDER_TOPIC = 'REORDER_TOPIC';
export const GET_TOPIC_DETAILS = 'GET_TOPIC_DETAILS';
export const ADD_PARAMS = 'ADD_PARAMS';
export const GET_TOPICS_SUBTOPICS = 'GET_TOPICS_SUBTOPICS';
export const RESET_RESOURCE_LOADING = 'RESET_RESOURCE_LOADING';
export const GET_RESOURCE_LIST = 'GET_RESOURCE_LIST';
export const UPDATE_RESOURCE_STATUS = 'UPDATE_RESOURCE_STATUS';
export const REORDER_TOPIC_RESOURCES = 'REORDER_TOPIC_RESOURCES';
export const GET_PREVIEW = 'GET_PREVIEW';
export const NULL_PREVIEW = 'NULL_PREVIEW';
export const RESET_PERCENTAGE = 'RESET_PERCENTAGE';
export const GET_DOCUMENT_STATE = 'GET_DOCUMENT_STATE';
export const GET_DOCUMENT_PERCENT = 'GET_DOCUMENT_PERCENT';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const GET_PRACTICE_TESTS = 'GET_PRACTICE_TESTS';
export const UPDATE_TEST_STATUS = 'UPDATE_TEST_STATUS';
export const ADD_PRACTICE_TEST = 'ADD_PRACTICE_TEST';


export const ADD_QUESTION_IN_QUIZ = 'ADD_QUESTION_IN_QUIZ';
export const SELECT_OR_DELETE_QUESTION_QUIZ = 'SELECT_OR_DELETE_QUESTION_QUIZ';
export const Delete_All_Question_Quiz = 'Delete_All_Question_Quiz';
export const ADD_NEW_QUIZ = 'ADD_NEW_QUIZ';
export const GET_QUIZ_QUESTION = 'GET_QUIZ_QUESTION';
export const GET_SUBTOPIC_QUIZ_LIST = 'GET_SUBTOPIC_QUIZ_LIST';

export const GET_CLUBBED_QUESTION_LIST = 'GET_CLUBBED_QUESTION_LIST';
export const GET_QUESTION_FROM_SUBJECT = 'GET_QUESTION_FROM_SUBJECT';
export const UPDATE_QUESTION_STATUS = 'UPDATE_QUESTION_STATUS';
export const GET_FILTER_SUBJECT = 'GET_FILTER_SUBJECT';
export const GET_QUESTION_DETAILS = 'GET_QUESTION_DETAILS';
export const SET_QUES_TITLE = 'SET_QUES_TITLE';
export const SET_EXPLANTION = 'SET_EXPLANTION';
export const SET_CHOICE1 = 'SET_CHOICE1';
export const SET_CHOICE2 = 'SET_CHOICE2';
export const SET_CHOICE3 = 'SET_CHOICE3';
export const SET_CHOICE4 = 'SET_CHOICE4';
export const GET_SUBJECT_CHAPTER = 'GET_SUBJECT_CHAPTER';
export const ADD_QUESTION = 'ADD_QUESTION';
export const EDIT_QUESTION = 'EDIT_QUESTION';


//my learning
export const GET_STUDENT_CLASSROOM = 'GET_STUDENT_CLASSROOM';
export const GET_DEFAULT_SUBJECTS = 'GET_DEFAULT_SUBJECTS';
export const GET_SUBJECTS = 'GET_SUBJECTS';
export const GET_OTHER_SUBJECTS = 'GET_OTHER_SUBJECTS';
export const GET_SUBJECT_CHAPTERS = 'GET_SUBJECT_CHAPTERS';
export const GET_STUDENT_SUBJECT_BRIEF = 'GET_STUDENT_SUBJECT_BRIEF';
export const GET_CHAPTER_TOPICS = 'GET_CHAPTER_TOPICS';
export const RESET_TOPIC_LOADING = 'RESET_TOPIC_LOADING';
export const RESET_SUBJECT = 'RESET_SUBJECT';
export const GET_TOPIC_RESOURCES = 'GET_TOPIC_RESOURCES';
export const GET_INDIVIDUAL_RESOURCE = 'GET_INDIVIDUAL_RESOURCE';
export const LIKE_RESOURCE = 'LIKE_RESOURCE';
export const GET_NOTE = 'GET_NOTE';
export const SAVE_NOTE = 'SAVE_NOTE';
export const GET_QUIZ_DATA = 'GET_QUIZ_DATA';
export const GET_QUESTION = 'GET_QUESTION';
export const GET_ANSWER = 'GET_ANSWER';
export const QUIZ_SUBMIT = 'QUIZ_SUBMIT';
export const CLOSE_QUIZ = 'CLOSE_QUIZ';
export const RESET_RESOURCE = 'RESET_RESOURCE';
export const ANALYTIC_CAPTURE = 'ANALYTIC_CAPTURE';
export const BACK_TO_TOPIC = 'BACK_TO_TOPIC';

export const GET_STUDENT_DETAILS = 'GET_STUDENT_DETAILS';
export const GET_SEMESTERS = 'GET_SEMESTERS';
export const GET_ACTIVE_SEM = 'GET_ACTIVE_SEM';
export const GET_OTHER_PROGRAMS = 'GET_OTHER_PROGRAMS';

//content-library
export const RESET_CLASSROOMS = 'RESET_CLASSROOMS';
export const GET_OTHER_SUBJECT = 'GET_OTHER_SUBJECT';
export const SEARCH_SKILLS = 'SEARCH_SKILLS';
export const GET_OTHER_SKILLS = 'GET_OTHER_SKILLS';
export const GET_SKILL_DETAILS = 'GET_SKILL_DETAILS';
export const GET_SKILL_PODS = 'GET_SKILL_PODS';
export const GET_POD_RESOURCES = 'GET_POD_RESOURCES';
export const RESET_CONTENT_LOADING = 'RESET_CONTENT_LOADING';
export const GET_RESOURCE_FROM_CONTENT = 'GET_RESOURCE_FROM_CONTENT';
export const Skill_Analytic_Capture = 'Skill_Analytic_Capture';
export const GET_CONTENT_NOTE = 'GET_CONTENT_NOTE';
export const LIKE_CONTENT_RESOURCE = 'LIKE_CONTENT_RESOURCE';
export const SAVE_CONTENT_NOTE = 'SAVE_CONTENT_NOTE';

export const GET_BOOKMARKED_SKILL = 'GET_BOOKMARKED_SKILL';
export const TOGGLE_BOOKMARK = 'TOGGLE_BOOKMARK';
export const BOOKMARK_DONE = 'BOOKMARK_DONE';
export const GET_TEST_QUESTIONS = 'GET_TEST_QUESTIONS';
export const GET_TEST_QUESTION = 'GET_TEST_QUESTION';
export const SELECT_ANSWER = 'SELECT_ANSWER';
export const TEST_SUBMIT = 'TEST_SUBMIT';
export const CLOSE_TEST = 'CLOSE_TEST';
export const GET_CERT_DATA = 'GET_CERT_DATA';

//Profile
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const EMPTY_PROFILE = 'EMPTY_PROFILE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';



//Practice test
export const RESET_SUBJECTS = 'RESET_SUBJECTS';
export const GET_PRACTICE_SUBJECTS = 'GET_PRACTICE_SUBJECTS';
export const RESET_TEST_LOADER = 'RESET_TEST_LOADER';
export const GET_TEST_LIST = 'GET_TEST_LIST';
export const CHECK_PENDING = 'CHECK_PENDING';


//MENTOR CONNECT
export const RESET_LOADING = 'RESET_LOADING';
export const GET_MENTOR_LIST = 'GET_MENTOR_LIST';
export const GET_STUDENT_CONVERSATION_LIST = 'GET_STUDENT_CONVERSATION_LIST';
export const SUBJECT_FILTER = 'SUBJECT_FILTER';
export const GET_FACULTY_SUBJECTS = 'GET_FACULTY_SUBJECTS';
export const RESET_FACULTY_SUBJECTS = 'RESET_FACULTY_SUBJECTS';
export const ADD_QUERY = 'ADD_QUERY';
export const GET_QUESTION_DETAIL = 'GET_QUESTION_DETAIL';
export const REPLY_SENT = 'REPLY_SENT';
export const CLOSE_QUESTION_DETAIL = 'CLOSE_QUESTION_DETAIL';

//counselling
export const GET_COUN_QUESTION = 'GET_COUN_QUESTION';
export const GET_ONE_QUESTION = 'GET_ONE_QUESTION';
export const SELECT_COUN_ANSWER = 'SELECT_COUN_ANSWER';
export const ASSESMENT_SUBMIT = 'ASSESMENT_SUBMIT';
export const GET_INTERESTS = 'GET_INTERESTS';
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
export const GET_REPORT_LIST = 'GET_REPORT_LIST';
export const GET_REPORT = 'GET_REPORT';
export const GET_ONE_REPORT = 'GET_ONE_REPORT';
export const RESET_REPORT = 'RESET_REPORT';
export const RESET_LIST_LOADER = 'RESET_LIST_LOADER';
export const GET_SLOT_LIST = 'GET_SLOT_LIST';
export const ADD_SLOTS = 'ADD_SLOTS';
export const EDIT_SLOTS = 'EDIT_SLOTS';
export const CHANGE_SLOT_STATUS = 'CHANGE_SLOT_STATUS';
export const DELETE_SLOT = 'DELETE_SLOT';
export const GET_ALL_BOOKINGS = 'GET_ALL_BOOKINGS';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKING_SLOTS = 'GET_BOOKING_SLOTS';
export const BOOK_SLOTS = 'BOOK_SLOTS';

//reports

export const GET_SCHOOLS = 'GET_SCHOOLS';
export const GET_STATE_DATA = 'GET_STATE_DATA';
export const GET_SCORE_TREND = 'GET_SCORE_TREND';
export const GET_LOGIN_TREND = 'GET_LOGIN_TREND';
export const GET_STUDENT_DATA = 'GET_STUDENT_DATA';

//Admin reports 
//Schools
export const GET_ALL_SCHOOL = 'GET_ALL_SCHOOL';
export const SCHOOL_LOGIN_TRENDS = 'SCHOOL_LOGIN_TRENDS';
export const SCHOOL_SCORE_TRENDS = 'SCHOOL_SCORE_TRENDS';
export const GET_SCHOOL_STUDENTS = 'GET_SCHOOL_STUDENTS';

//courses
export const GET_TOP_COURSES = 'GET_TOP_COURSES';
export const GET_COURSE_GRAPH = 'GET_COURSE_GRAPH';
export const GET_COURSE_DATA = 'GET_COURSE_DATA';
export const RESET_COURSE_DETAILS = 'RESET_COURSE_DETAILS';
export const SORT_CLASSROOM = 'SORT_CLASSROOM';
//Subjects
export const GET_SUBJECTS_LIST = 'GET_SUBJECTS_LIST';
export const GET_NEXT_SUBJECTS_LIST = 'GET_NEXT_SUBJECTS_LIST';
export const RESET_SUB = 'RESET_SUB';
export const GET_SUBJECT_CHAPTER_DATA = 'GET_SUBJECT_CHAPTER_DATA';
export const GET_CHAPTER_DATA = 'GET_CHAPTER_DATA';
export const SEARCH_CHAPTER = 'SEARCH_CHAPTER';
export const REFRESH_CHPATER_DATA = 'REFRESH_CHPATER_DATA';
export const SORT_SUBJECT = 'SORT_SUBJECT';
export const RESET_SUBJECT_LOADING = 'RESET_SUBJECT_LOADING';

export const OPEN_INDIVIDUAL = 'OPEN_INDIVIDUAL';
export const CLOSE_iNDIVIDUAL = 'CLOSE_iNDIVIDUAL';
export const REFRESH_DATA = 'REFRESH_DATA';

//Faculty
export const RESET_FACULTY_DETAILS = 'RESET_FACULTY_DETAILS';
export const FACULTY_LOGIN_TRENDS = 'FACULTY_LOGIN_TRENDS';
export const FACULTY_SCORE_TRENDS = 'FACULTY_SCORE_TRENDS';
export const GET_FACULTY_DATA = 'GET_FACULTY_DATA';
export const GET_FACULTY_SUBJECT_DATA = 'GET_FACULTY_SUBJECT_DATA';
export const GET_FACULTY_CONTENT_COUNT = 'GET_FACULTY_CONTENT_COUNT';
export const SORT_FACULTY = 'SORT_FACULTY';
export const SORT_FACULTY_SUBJECT = 'SORT_FACULTY_SUBJECT';
export const RESET_FACULTY_LOADING = 'RESET_FACULTY_LOADING';
export const RESET_FACULTY_SUBJECT_LOADING = 'RESET_FACULTY_SUBJECT_LOADING';
export const HIDE_CHAPTER = 'HIDE_CHAPTER';

//Students
export const STUDENT_LOGIN_TRENDS = 'STUDENT_LOGIN_TRENDS';
export const STUDENT_SCORE_TRENDS = 'STUDENT_SCORE_TRENDS';
export const RESET_STUDENT_LOADING = 'RESET_STUDENT_LOADING';
export const STUDENT_LIST = 'STUDENT_LIST';
export const STUDENT_REPORT = 'STUDENT_REPORT';
export const STUDENT_REPORT1 = 'STUDENT_REPORT1';
export const GET_SUBJECT_COMPLETION = 'GET_SUBJECT_COMPLETION';
export const GET_ACTIVE_CONTENTS = 'GET_ACTIVE_CONTENTS';
export const RESET_STUDENT_DETAILS = 'RESET_STUDENT_DETAILS';
export const CHANGE_SUBJECTS = 'CHANGE_SUBJECTS';
export const GET_ACTIVE_SUBJECT_TOPICS = 'GET_ACTIVE_SUBJECT_TOPICS';
export const GET_TEST_LIST1 = 'GET_TEST_LIST1';
export const RESET_SUBTOPICS_DATA = 'RESET_SUBTOPICS_DATA';
export const GET_CLASSES = 'GET_CLASSES';
export const GET_ACTIVE_SUBJECT_SUBTOPICS = 'GET_ACTIVE_SUBJECT_SUBTOPICS';
export const GET_SUBTOPIC_QUIZ_HISTORY = 'GET_SUBTOPIC_QUIZ_HISTORY';
export const CLEAR_QUIZ_HISTORY = 'CLEAR_QUIZ_HISTORY';
export const GET_SCORE_HISTORY = 'GET_SCORE_HISTORY';
export const GET_NEXT_SCORE_HISTORY = 'GET_NEXT_SCORE_HISTORY';
export const RESET_HISTORY = 'RESET_HISTORY';

//TESTS
export const REFRESH_TEST = 'REFRESH_TEST';
export const REFRESH_QUESTION = 'REFRESH_QUESTION';
export const REFRESH_HISTORY = 'REFRESH_HISTORY';
export const GET_QUIZ_LIST = 'GET_QUIZ_LIST';
export const GET_NEXT_QUIZ_LIST = "GET_NEXT_QUIZ_LIST";
export const GET_TEST_LISTS = "GET_TEST_LISTS";
export const GET_NEXT_TEST_LISTS = 'GET_NEXT_TEST_LISTS';
export const GET_QUESTIONS_LIST = 'GET_QUESTIONS_LIST';
export const GET_TEST_HISTORY = 'GET_TEST_HISTORY';
export const GET_ALL_SUBJECTS = 'GET_ALL_SUBJECTS';
//assessments
export const RESET_ASSESSMENT = 'RESET_ASSESSMENT';
export const ASSESMENT_STUDENT_LIST = 'ASSESMENT_STUDENT_LIST';
export const GET_ABILITIES = 'GET_ABILITIES';
export const REPORT_ENABLE = 'REPORT_ENABLE';
export const GET_ASSESSMENT_REPORT = 'GET_ASSESSMENT_REPORT';
export const GET_ABILITIES_QUESTIONS = 'GET_ABILITIES_QUESTIONS';
export const CHANGE_SCORE = 'CHANGE_SCORE';
export const ASSESSMENT_GRAPH = 'ASSESSMENT_GRAPH';
export const GET_ONE_REPORTS = 'GET_ONE_REPORTS';
export const SEARCH_ASSESSMENT_STUDENT = 'SEARCH_ASSESSMENT_STUDENT';