/* eslint-disable no-unused-vars */
import { getApiCall } from '../../../utils/request';
import {
    GET_SCHOOLS, GET_STATE_DATA, GET_SCORE_TREND, GET_LOGIN_TREND, GET_STUDENT_DATA,
    OPEN_INDIVIDUAL, CLOSE_iNDIVIDUAL, REFRESH_DATA,                               //School
    GET_TOP_COURSES, GET_COURSE_GRAPH, GET_COURSE_DATA, RESET_COURSE_DETAILS, SORT_CLASSROOM, //Course
    GET_SUBJECTS_LIST, GET_NEXT_SUBJECTS_LIST, RESET_SUB, GET_SUBJECT_CHAPTER_DATA, SEARCH_CHAPTER, REFRESH_CHPATER_DATA, SORT_SUBJECT, RESET_SUBJECT_LOADING, //Subjects
    RESET_FACULTY_DETAILS, FACULTY_LOGIN_TRENDS, FACULTY_SCORE_TRENDS, GET_FACULTY_DATA, GET_FACULTY_SUBJECT_DATA, GET_FACULTY_CONTENT_COUNT, SORT_FACULTY, SORT_FACULTY_SUBJECT, RESET_FACULTY_LOADING, RESET_FACULTY_SUBJECT_LOADING, HIDE_CHAPTER, //FACULTY
    STUDENT_LOGIN_TRENDS, STUDENT_SCORE_TRENDS, RESET_STUDENT_LOADING, STUDENT_LIST, STUDENT_REPORT, STUDENT_REPORT1, GET_SUBJECT_COMPLETION, GET_ACTIVE_CONTENTS, RESET_STUDENT_DETAILS, CHANGE_SUBJECTS, GET_ACTIVE_SUBJECT_TOPICS, GET_TEST_LIST1, RESET_SUBTOPICS_DATA, GET_CLASSES, //students
    GET_ACTIVE_SUBJECT_SUBTOPICS, GET_SUBTOPIC_QUIZ_HISTORY, CLEAR_QUIZ_HISTORY, GET_SCORE_HISTORY, GET_NEXT_SCORE_HISTORY, RESET_HISTORY,
    REFRESH_TEST, REFRESH_QUESTION, REFRESH_HISTORY, GET_QUIZ_LIST, GET_NEXT_QUIZ_LIST, GET_TEST_LISTS, GET_NEXT_TEST_LISTS, GET_QUESTIONS_LIST, GET_TEST_HISTORY, GET_ALL_SUBJECTS //tests
} from '../../Types/types';
import { returnErrors } from '../Common/errorAction';

export const getSchool = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/state/schools?state_id=${id}`);
        return dispatch({ type: GET_SCHOOLS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getStateData = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/report/state/engagement?state_id=${id}`);
        return dispatch({ type: GET_STATE_DATA, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getStateScoreTrend = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/report/state/engagement/graph?state_id=${id}`);
        return dispatch({ type: GET_SCORE_TREND, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getStateLoginTrend = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/report/state/login/graph?state_id=${id}`);
        return dispatch({ type: GET_LOGIN_TREND, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getStudentDetails = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`superadmin/report/school/students?school_id=${id}`);
        return dispatch({ type: GET_STUDENT_DATA, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

//Admin reports
export const openIndividualItem = (type, el) => async (dispatch) => {
    return dispatch({
        type: OPEN_INDIVIDUAL,
        payload: { el, type }
    })
}

export const backToMain = () => async (dispatch) => {
    return dispatch({
        type: CLOSE_iNDIVIDUAL,
    })
}

export const refreshData = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_DATA,
    })
}


// for courses api call

export const getTopCourses = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/course/top/engaged`);
        dispatch({ type: GET_TOP_COURSES, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getCourseGraph = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/course/student`);
        dispatch({ type: GET_COURSE_GRAPH, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getCoursesList = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/course/details`);
        dispatch({ type: GET_COURSE_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetProgramDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_COURSE_DETAILS,
    })
}


export const sortClassroomList = (key, asc) => async (dispatch) => {
    return dispatch({
        type: SORT_CLASSROOM, payload: { key, asc }
    })
}




//for subject api call


export const getSubjectList = (page, query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/completion?page=${page}&query=${query}`);
        console.log(response.data)
        dispatch({ type: GET_SUBJECTS_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getNextSubjectList = (page, query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/completion?page=${page}&query=${query}`);
        dispatch({ type: GET_NEXT_SUBJECTS_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const resetSubjectDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_SUB
    })
}

export const getTopChapterCompletion = (id) => async (dispatch) => {
    // try {
    //     const response = await getApiCall(`admin/reports/subject/chapter/top/completion?subject_id=${id}`);
    //     dispatch({ type: "Get_Subject_Chapter_Graph", payload: response.data });
    // } catch (error) {
    //             returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    // }
}

export const getAllChapterCompletion = (id, course_id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/chapter/completion?subject_id=${id}&course_id=${course_id}`);
        dispatch({ type: GET_SUBJECT_CHAPTER_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const searchChapterResult = (val) => async (dispatch) => {
    return dispatch({
        type: SEARCH_CHAPTER, payload: val
    })
}



export const refreshChapterData = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_CHPATER_DATA,
    })
}

export const sortSubjectList = (key, asc) => async (dispatch) => {
    return dispatch({
        type: SORT_SUBJECT, payload: { key, asc }
    })
}


export const resetSubjectLoading = () => async (dispatch) => {
    return dispatch({
        type: RESET_SUBJECT_LOADING
    })
}


//faculty

export const resetFacultyDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_FACULTY_DETAILS
    })
}

export const getFacultyLoginTrends = (role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/login/trend?role=${role}`);
        dispatch({ type: FACULTY_LOGIN_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getFacultyScoreTrends = (role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/score/trend?role=${role}`);
        dispatch({ type: FACULTY_SCORE_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getFacultyList = (page, query, sort) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/faculty/details?page=${page}&query=${query}&sort=${sort}`);
        dispatch({ type: GET_FACULTY_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}




export const getFacultySubject = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/faculty/subject/details?user_id=${id}`);
        dispatch({ type: GET_FACULTY_SUBJECT_DATA, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getFacultyContentCount = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/faculty/count/content?_id=${id}`);
        dispatch({ type: GET_FACULTY_CONTENT_COUNT, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const sortFacultyList = (key, asc) => async (dispatch) => {
    return dispatch({
        type: SORT_FACULTY, payload: { key, asc }
    })
}

export const sortFacultySubjectList = (key, asc) => async (dispatch) => {
    return dispatch({
        type: SORT_FACULTY_SUBJECT, payload: { key, asc }
    })
}

export const resetFacultyLoading = () => async (dispatch) => {
    return dispatch({
        type: RESET_FACULTY_LOADING
    })
}


export const resetFacultySubjectChapter = () => async (dispatch) => {
    return dispatch({
        type: RESET_FACULTY_SUBJECT_LOADING
    })
}

export const isHideChapter = () => async (dispatch) => {
    return dispatch({
        type: HIDE_CHAPTER,
    })
}







// for students API call
export const getStudentLoginTrends = (role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/login/trend?role=${role}`);
        dispatch({ type: STUDENT_LOGIN_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getStudentScoreTrends = (role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/score/trend?role=${role}`);
        dispatch({ type: STUDENT_SCORE_TRENDS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetStudentLoading = () => async (dispatch) => {
    return dispatch({
        type: RESET_STUDENT_LOADING
    })
}

export const getListOfStudent = (page, val, courseId, cls, sort, role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/ranking/data?page=${page}&query=${val}&course_id=${courseId === undefined ? '' : courseId}&class=${cls === undefined ? '' : cls}&sort=${sort}&role=${role}`);
        dispatch({ type: STUDENT_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getStudentReportDetail = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/details?user_id=${id}`);
        const subject = await getApiCall(`student/achiever/subject/graph?user_id=${id}`);
        const data = response.data.data
        const subject1 = await getApiCall(`admin/reports/student/subject/completion?course_id=${data.course_id}&user_id=${id}`);
        const test = await getApiCall(`admin/reports/student/practise/test/completion?course_id=${data.course_id}&user_id=${id}`);
        const subjects = subject.data.subjects
        const subjectContent = subject.data.data
        const test1 = test.data
        const subject2 = subject1.data
        const response1 = await getApiCall(`admin/reports/student/subject/chapter?subject_id=${subject2.subject[0]._id}&user_id=${id}`);
        // const response2 = await getApiCall(`admin/reports/student/practise/test?subject_id=${subject2.subject[0]._id}&user_id=${id}`);
        const chapters = response1.data.data;
        // const testList=response2.data.data
        dispatch({ type: STUDENT_REPORT, payload: { data, subjects, subjectContent, id } });
        dispatch({ type: GET_SUBJECT_COMPLETION, payload: { subject2, test1 } });
        dispatch({ type: GET_ACTIVE_CONTENTS, payload: chapters });
        // dispatch({ type: "Get_Test_Completion", payload: test1 })

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getStudentReportDetail1 = (id) => async (dispatch) => {
    try {
        const monthly = await getApiCall(`student/achiever/monthly/graph?user_id=${id}`);
        const login = await getApiCall(`student/dashboard/login/trend?user_id=${id}`);
        const month = monthly.data.months
        const contents = monthly.data.data
        const logins = login.data
        dispatch({ type: STUDENT_REPORT1, payload: { month, contents, id, logins } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetStudentDetails = () => async (dispatch) => {
    return dispatch({
        type: RESET_STUDENT_DETAILS,
    })
}

export const changeSubject = (sub, subId) => async (dispatch) => {
    return dispatch({
        type: CHANGE_SUBJECTS,
        payload: { sub, subId }
    })
}

export const getTopicsForStudent = (userId, subId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/subject/chapter?subject_id=${subId}&user_id=${userId}`);
        const data = response.data.data;
        dispatch({ type: GET_ACTIVE_SUBJECT_TOPICS, payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const getTestList = (subid, userId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/practise/test?subject_id=${subid}&user_id=${userId}`);
        dispatch({ type: GET_TEST_LIST1, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const resetSubtopicsData = () => async (dispatch) => {
    return dispatch({
        type: RESET_SUBTOPICS_DATA
    })
}

export const getSubtopics = (key, topicId, userId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/subject/chapter/details?chapter_id=${topicId}&user_id=${userId}`);
        const data = response.data.data;
        dispatch({ type: GET_ACTIVE_SUBJECT_SUBTOPICS, payload: { data, key } });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const getQuizHistory = (chapterId, topicId, userId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/quiz/completion?chapter_id=${chapterId}&topic_id=${topicId}&user_id=${userId}`);
        const data = response.data.data;
        dispatch({ type: GET_SUBTOPIC_QUIZ_HISTORY, payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const clearQuizHistory = () => async (dispatch) => {
    return dispatch({
        type: CLEAR_QUIZ_HISTORY
    })
}

export const getClasses = () => async (dispatch) => {
    try {
        const response = await getApiCall(`school/class`);
        dispatch({ type: GET_CLASSES, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getScoresHistory = (id, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/score/history?_id=${id}&page=${page}`);
        const data = response.data.data;
        dispatch({ type: GET_SCORE_HISTORY, payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const getNextScoresHistory = (id, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/student/score/history?_id=${id}&page=${page}`);
        const data = response.data.data;
        dispatch({ type: GET_NEXT_SCORE_HISTORY, payload: data });
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const resetHistory = (type) => async (dispatch) => {
    return dispatch({
        type: RESET_HISTORY, payload: type
    })
}

//TEsts

export const refreshTests = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_TEST,
    })
}

export const refreshQuestion = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_QUESTION,
    })
}

export const resetHistoryLoading = () => async (dispatch) => {
    return dispatch({
        type: REFRESH_HISTORY,
    })
}

export const getQuizList = (page, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/quiz?page=${page}&subject_id=${subjectId}`)
        dispatch({ type: GET_QUIZ_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const nextQuizList = (page, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/quiz?page=${page}&subject_id=${subjectId}`)
        dispatch({ type: GET_NEXT_QUIZ_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getPracticeTest = (page, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/practise?page=${page}&subject_id=${subjectId}`)
        dispatch({ type: GET_TEST_LISTS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getNextPracticeTest = (page, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/practise?page=${page}&subject_id=${subjectId}`)
        dispatch({ type: GET_NEXT_TEST_LISTS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getQuestions = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/questions?_id=${id}`)
        dispatch({ type: GET_QUESTIONS_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getTestHistory = (id, question) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/test/quiz/history?_id=${id}`);
        const data = response.data.data
        dispatch({ type: GET_TEST_HISTORY, payload: { data, question } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getAllSubjects = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/reports/subject/all`);
        dispatch({ type: GET_ALL_SUBJECTS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}










export const onResetSubject = () => async (dispatch) => {
    return dispatch({
        type: 'Reset_Sem_Subject'
    })
}


